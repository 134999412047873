import { createAction } from '@reduxjs/toolkit';

export const SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST = 'SOCIAL_NETWORK_AD_ACCOUNT_LIST_REQUEST';
export const SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_ERROR = 'SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_ERROR';
export const SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS = 'SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS';

export const adAccountSearchGraphAuthUserForceConnectRequest = createAction('AD_ACCOUNT_SEARCH_GRAPH_AUTH_USER_FORCE_CONNECT_REQUEST', (accessToken) => ({ payload: { accessToken } }));

export const adAccountSearchByAuthAdAccountRequest = createAction('AD_ACCOUNT_SEARCH_BY_AUTH_AD_ACCOUNT_REQUEST');
export const adAccountSearchByAuthAdAccountSuccess = createAction('AD_ACCOUNT_SEARCH_BY_AUTH_AD_ACCOUNT_SUCCESS', (searchResults) => ({ payload: { searchResults } }));
export const adAccountSearchByAuthAdAccountError = createAction('AD_ACCOUNT_SEARCH_BY_AUTH_AD_ACCOUNT_ERROR', (accessToken) => ({ payload: { accessToken } }));

export const socialNetworkAdAccountSearchReset = createAction('SOCIAL_NETWORK_AD_ACCOUNT_SEARCH_RESET');

export const socialNetworkAdAccountShowListRequest = (network) => ({
    type: SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST,
    payload: { network }
});

export const socialNetworkAdAccountShowListSuccess = (searchResults = {}) => ({
    type: SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS,
    payload: { searchResults }
});

export const socialNetworkAdAccountShowListError = (error) => ({
    type: SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_ERROR,
    payload: { error }
});
