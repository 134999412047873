export const GET_DATA_SOURCES_ERROR = 'GET_DATA_SOURCES_ERROR';
export const GET_DATA_SOURCES_REQUEST = 'GET_DATA_SOURCES_REQUEST';
export const GET_DATA_SOURCES_SUCCESS = 'GET_DATA_SOURCES_SUCCESS';

export const TOGGLE_DATA_SOURCE = 'TOGGLE_DATA_SOURCE';
export const TOGGLE_DATA_SOURCE_COLUMN_DESCRIPTION = 'TOGGLE_DATA_SOURCE_COLUMN_DESCRIPTION';

export const getDataSourcesRequest = () => ({
    type: GET_DATA_SOURCES_REQUEST
});

export const getDataSourcesSuccess = (dataSources, dataSourceColumns) => ({
    type: GET_DATA_SOURCES_SUCCESS,
    payload: {
        dataSources,
        dataSourceColumns
    }
});

export const getDataSourcesError = (error) => ({
    type: GET_DATA_SOURCES_ERROR,
    payload: {
        error
    }
});

export const toggleDataSource = (name) => ({
    type: TOGGLE_DATA_SOURCE,
    payload: { name }
});

export const toggleDataSourceColumnDescription = (name, column) => ({
    type: TOGGLE_DATA_SOURCE_COLUMN_DESCRIPTION,
    payload: { name, column }
});
