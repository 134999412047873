import { getLoggedInSpaceId, getUserSpaceIds } from 'src/selectors/loggedInUser';
import { createSelector } from 'reselect';
import { makeStringSorter } from 'src/selectors/utils';

export const getSpaces = (state) => state.entities.spaces;
export const getSpacesById = (state) => state.entities.spaces.byId;
export const getSpaceById = (state, id) => state.entities.spaces.byId[id];

export const selectSpaces = createSelector(
    [
        getSpaces
    ],
    (spaces) => Object.values(spaces.byId).sort(makeStringSorter('name', 'asc'))
);

export const selectLoggedInSpace = createSelector(
    [
        getLoggedInSpaceId,
        getSpacesById
    ],
    (spaceId, spaces) => spaces[spaceId]
);

export const selectLoggedInUserSpaces = createSelector(
    [
        getUserSpaceIds,
        getSpacesById
    ],
    (spaceIds, allSpaces) => spaceIds.map((spaceId) => allSpaces[spaceId])
);
