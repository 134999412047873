export const CRON_EXPRESSION_TRANSLATION_REQUEST = 'CRON_EXPRESSION_TRANSLATION_REQUEST_BACKGROUND';
export const CRON_EXPRESSION_TRANSLATION_SUCCESS = 'CRON_EXPRESSION_TRANSLATION_SUCCESS_BACKGROUND';
export const CRON_EXPRESSION_TRANSLATION_ERROR = 'CRON_EXPRESSION_TRANSLATION_ERROR_BACKGROUND';

export const CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST = 'CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST_BACKGROUND';
export const CRON_EXPRESSION_NEXT_RUN_DATE_SUCCESS = 'CRON_EXPRESSION_NEXT_RUN_DATE_SUCCESS_BACKGROUND';
export const CRON_EXPRESSION_NEXT_RUN_DATE_ERROR = 'CRON_EXPRESSION_NEXT_RUN_DATE_ERROR_BACKGROUND';

export const STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST = 'STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST_BACKGROUND';
export const STANDARD_SCHEDULE_NEXT_RUN_DATE_SUCCESS = 'STANDARD_SCHEDULE_NEXT_RUN_DATE_SUCCESS_BACKGROUND';
export const STANDARD_SCHEDULE_NEXT_RUN_DATE_ERROR = 'STANDARD_SCHEDULE_NEXT_RUN_DATE_ERROR_BACKGROUND';

export const DASHBOARD_REPORT_SCHEDULE_CLEANUP = 'DASHBOARD_REPORT_SCHEDULE_CLEANUP';

export const cronExpressionTranslationRequest = (expression) => ({
    type: CRON_EXPRESSION_TRANSLATION_REQUEST,
    payload: { expression }
});

export const cronExpressionTranslationSuccess = (expression, translation) => ({
    type: CRON_EXPRESSION_TRANSLATION_SUCCESS,
    payload: { expression, translation }
});

export const cronExpressionTranslationError = (expression, error) => ({
    type: CRON_EXPRESSION_TRANSLATION_ERROR,
    payload: { expression, error }
});

export const cronExpressionNextRunDateRequest = (expression, timezone, identifier) => ({
    type: CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST,
    payload: { expression, timezone, identifier }
});

export const cronExpressionNextRunDateSuccess = (identifier, date) => ({
    type: CRON_EXPRESSION_NEXT_RUN_DATE_SUCCESS,
    payload: { identifier, date }
});

export const cronExpressionNextRunDateError = (identifier, error) => ({
    type: CRON_EXPRESSION_NEXT_RUN_DATE_ERROR,
    payload: { identifier, error }
});

export const standardScheduleNextRunDateRequest = (intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime, identifier) => ({
    type: STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST,
    payload: {
        intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime, identifier
    }
});

export const standardScheduleNextRunDateSuccess = (identifier, date) => ({
    type: STANDARD_SCHEDULE_NEXT_RUN_DATE_SUCCESS,
    payload: { identifier, date }
});

export const standardScheduleNextRunDateError = (identifier, error) => ({
    type: STANDARD_SCHEDULE_NEXT_RUN_DATE_ERROR,
    payload: { identifier, error }
});

export const dashboardReportScheduleCleanup = () => ({
    type: DASHBOARD_REPORT_SCHEDULE_CLEANUP
});
