import { createSelector } from 'reselect';
import _get from 'lodash/get';

export const getDataPushTaskDestinationsFromStore = (state) => state.entities.dataPushTaskDestinations.byIds;
export const getDataPushTaskDestinationIdsFromStore = (state) => state.entities.dataPushTaskDestinations.allIds;
export const getDataPushTaskAsyncState = (state) => state.entities.dataPushTaskDestinations.asyncStates;

export const makeSelectDataPushTaskDestinations = () => createSelector(
    [
        getDataPushTaskDestinationsFromStore,
        getDataPushTaskDestinationIdsFromStore
    ],
    (dataPushTaskDestinations, dataPushTaskDestinationIds) => dataPushTaskDestinationIds.map((id) => dataPushTaskDestinations[id])
);

export const makeSelectDataPushTaskDestinationById = () => createSelector(
    [
        getDataPushTaskDestinationsFromStore,
        (_, id) => id
    ],
    (dataPushTaskDestinations, id) => dataPushTaskDestinations[id] || false
);

export const makeSelectIsDataPushTaskDestinationConnectionTesting = () => createSelector(
    [
        getDataPushTaskAsyncState,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['connectionTest', id, 'isPending'], false)
);
