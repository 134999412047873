import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isString from 'lodash/isString';
import { shallowEqual } from 'src/utils/shallowEqual';
import _uniq from 'lodash/uniq';
import _has from 'lodash/has';
import _parseInt from 'lodash/parseInt';
import _orderBy from 'lodash/orderBy';

export const createShallowEqualSelector = createSelectorCreator(
    defaultMemoize,
    shallowEqual
);

export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    _isEqual
);

export const makeStringSorter = (property, direction, excludePrefixChar = null) => (a, b) => {
    let propertyA = _get(a, property, '');
    let propertyB = _get(b, property, '');

    if (excludePrefixChar !== null) {
        propertyA = propertyA.charAt(0) === excludePrefixChar ? propertyA.slice(1) : propertyA;
        propertyB = propertyB.charAt(0) === excludePrefixChar ? propertyB.slice(1) : propertyB;
    }

    const alc = _isString(propertyA) ? propertyA.toLowerCase().trim() : '';
    const blc = _isString(propertyB) ? propertyB.toLowerCase().trim() : '';

    if (alc > blc) {
        return direction === 'desc' ? -1 : 1;
    }

    if (alc < blc) {
        return direction === 'desc' ? 1 : -1;
    }

    return 0;
};

export const makeBooleanSorter = (property, direction) => (a, b) => {
    const propertyA = _get(a, property, false);
    const propertyB = _get(b, property, false);
    if (propertyA === true && propertyB === false) {
        return direction === 'desc' ? -1 : 1;
    }

    if (propertyA === false && propertyB === true) {
        return direction === 'desc' ? 1 : -1;
    }

    return 0;
};

export const makeNumberSorter = (property, direction) => (a, b) => {
    const propertyA = _get(a, property, 0);
    const propertyB = _get(b, property, 0);

    return direction === 'desc' ? propertyB - propertyA : propertyA - propertyB;
};

export const makeSearchQueryFilter = (filterProperty = 'name', emptyOnEmptySearch = false) => createSelector(
    [
        (items) => items,
        (_, filterQuery) => filterQuery
    ],
    (items, filterQuery) => {
        if (filterQuery.length < 1) {
            if (emptyOnEmptySearch) {
                return [];
            }
            return items;
        }
        const filterQueryToLower = filterQuery.toLowerCase();
        return items.filter((profile) => profile[filterProperty].toLowerCase().indexOf(filterQueryToLower) > -1);
    }
);

export const makeStringSortDirSorter = () => createSelector(
    [
        (items) => items,
        (_, sortBy) => sortBy,
        (_, __, sortDir) => sortDir
    ],
    (items, sortBy, sortDir) => items.slice().sort(makeStringSorter(sortBy, sortDir))
);

export const makeBooleanSortDirSorter = () => createSelector(
    [
        (items) => items,
        (_, sortBy) => sortBy,
        (_, __, sortDir) => sortDir
    ],
    (items, sortBy, sortDir) => items.slice().sort(makeBooleanSorter(sortBy, sortDir))
);

export const makeNumberSortDirSorter = () => createSelector(
    [
        (items) => items,
        (_, sortBy) => sortBy,
        (_, __, sortDir) => sortDir
    ],
    (items, sortBy, sortDir) => items.slice().sort(makeNumberSorter(sortBy, sortDir))
);

export const makeExcludeFilter = (filterProperty = 'id') => createSelector(
    [
        (items) => items,
        (_, exclude) => exclude
    ],
    (items, exclude) => items.filter((item) => item[filterProperty] !== exclude)
);

export const makeOrderBySorter = () => createSelector(
    [
        (items) => items,
        (_, orderBy) => orderBy
    ],
    (items, orderBy = 'alphabetically') => {
        if (orderBy === 'alphabetically') {
            return items.slice().sort(makeStringSorter('name', 'asc'));
        }
        return items;
    }
);

export const makePropertyExtractor = (property = 'id') => createSelector(
    [
        (items) => items
    ],
    (items) => items.map((item) => item[property])
);

export const makePlatformTypeFilter = (property = 'platformType') => createSelector(
    [
        (items) => items,
        (_, platformType) => platformType
    ],
    (items, platformType) => {
        if (platformType === 'all') {
            return items;
        }
        return items.filter((item) => item[property] === platformType);
    }
);

export const makeAuthUserEnvTypeFilter = () => createSelector(
    [
        (items) => items,
        (_, environment) => environment
    ],
    (items, environment) => items.filter((item) => {
        if (environment === 'graph') {
            return item.platformType === 'facebook' || item.platformType === 'instagram';
        }
        return item.platformType === environment;
    })
);

export const makeGroupFilter = () => createSelector(
    [
        (items) => items,
        (_, group) => group
    ],
    (items, group) => {
        if (!group || group.id === '0') {
            return items;
        }
        return items.filter((item) => (group.profileIds.indexOf(item.id) >= 0 || group.adAccountIds.indexOf(item.id) >= 0));
    }
);

export const makeConcatenator = () => createSelector(
    [
        (items) => items,
        (_, otherItems) => otherItems
    ],
    (items, otherItems) => items.concat(otherItems)
);

export const makeSelectGroupsFromProfileId = () => createSelector(
    [
        (_, profileId) => profileId,
        (_, __, groups) => groups
    ],
    (profileId, groups) => groups.filter((group) => (group.id !== '0' && group.profileIds.indexOf(profileId) >= 0))
);

export const makeSelectGroupsFromAdAccountId = () => createSelector(
    [
        (_, adAccountId) => adAccountId,
        (_, __, groups) => groups
    ],
    (adAccountId, groups) => groups.filter((group) => (group.id !== '0' && group.adAccountIds.indexOf(adAccountId) >= 0))
);

export const orderPlatformTypesByUsage = (platformTypes) => {
    const platformTypesCount = {};
    const uniqTypes = _uniq(platformTypes);
    platformTypes.forEach((platformType) => {
        if (_has(platformTypesCount, platformType)) {
            platformTypesCount[platformType] += 1;
        } else {
            platformTypesCount[platformType] = 1;
        }
    });
    return _orderBy(uniqTypes, [(platformType) => platformTypesCount[platformType]], ['desc']);
};

export const selectedProfilesOrGroupsToParams = (selectedProfilesOrGroups) => {
    const { groupIds, profileIds, adAccountIds } = selectedProfilesOrGroups;
    const parsedGroupIds = groupIds.map((id) => `group-${id}`);
    const parsedAdAccountIds = adAccountIds.map((id) => `adAccount-${id}`);
    return parsedGroupIds.concat(profileIds.map((id) => `profile-${id}`)).concat(parsedAdAccountIds);
};

export const selectCheckedAndUnCheckedUseCasesPerAccountByListNames = (lists, listNames) => {
    let accountUseCases = {};
    Object.values(listNames).forEach((listName) => {
        const useCaseId = listName.split('_')[1];
        const accounts = _get(lists, [listName, 'items'], []);
        Object.keys(accounts).forEach((accountId) => {
            if (!accountUseCases[accountId]) {
                accountUseCases = Object.assign({}, accountUseCases, { [accountId]: { checkedUseCaseIds: [], unCheckedUseCaseIds: [] } });
            }
            if (accounts[accountId]) {
                accountUseCases[accountId].checkedUseCaseIds.push(_parseInt(useCaseId));
            } else {
                accountUseCases[accountId].unCheckedUseCaseIds.push(_parseInt(useCaseId));
            }
        });
    });
    return accountUseCases;
};

export const defaultLoadingState = {
    isPending: false,
    error: null,
    success: false,
    requested: false
};
