import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { defaultLoadingState } from 'src/selectors/utils';
import _includes from 'lodash/includes';

export const getDashboardShareLinks = (state) => state.entities.dashboardShareLinks.byId;
export const getAllDashboardShareLinkIds = (state) => state.entities.dashboardShareLinks.allIds;
export const getAsyncStates = (state) => state.entities.dashboardShareLinks.asyncStates;

export const makeSelectDashboardShareLinks = () => createSelector(
    [
        getDashboardShareLinks,
        getAllDashboardShareLinkIds
    ],
    (dashboardShareLinks, allIds) => allIds.map((id) => dashboardShareLinks[id])
);

export const makeSelectDashboardShareLinkById = () => createSelector(
    [
        getDashboardShareLinks,
        (_, dashboardShareLinkId) => dashboardShareLinkId
    ],
    (allDashboardShareLinks, dashboardShareLinkId) => allDashboardShareLinks[dashboardShareLinkId] || false
);

export const makeSelectDashboardShareLinkByIds = () => createSelector(
    [
        getDashboardShareLinks,
        (_, shareLinkIds) => shareLinkIds
    ],
    (allDashboardShareLinks, shareLinkIds) => {
        const dashboardShareLinks = [];
        shareLinkIds.forEach((shareLinkId) => {
            if (allDashboardShareLinks[shareLinkId]) {
                dashboardShareLinks.push(allDashboardShareLinks[shareLinkId]);
            }
        });
        return dashboardShareLinks;
    }
);

export const selectIsGettingAllDashboardShareLinks = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['getAll', 'state'], defaultLoadingState)
);

export const selectIsGettingDashboardShareLinks = createSelector(
    [
        getAsyncStates,
        (_, dashboardId) => dashboardId
    ],
    (asyncStates, dashboardId) => _get(asyncStates, ['getByDashboard', dashboardId], defaultLoadingState)
);

export const makeSelectDashboardShareLinkByDashboardId = () => {
    const selectDashboardShareLinks = makeSelectDashboardShareLinks();
    return createSelector(
        [
            selectDashboardShareLinks,
            (_, dashboardId) => dashboardId
        ],
        (allDashboardShareLinks, dashboardId) => allDashboardShareLinks.filter((dashboardShareLink) => dashboardShareLink.dashboardId === dashboardId)
    );
};

export const makeSelectDashboardShareLinkIdsByDashboardIds = () => {
    const selectDashboardShareLinks = makeSelectDashboardShareLinks();
    return createSelector(
        [
            selectDashboardShareLinks,
            (_, dashboardIds) => dashboardIds
        ],
        (allDashboardShareLinks, dashboardIds) => {
            const filteredDashboardShareLink = allDashboardShareLinks.filter((dashboardShareLink) => _includes(dashboardIds, dashboardShareLink.dashboardId));
            return filteredDashboardShareLink.map(({ id }) => id);
        }
    );
};
