import { getLocalString } from 'src/components/Date';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import withToolTip from 'src/components/withToolTip';
import { connect } from 'react-redux';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import * as customPropTypes from 'src/customPropTypes';

export const getRelativeTimeString = (date, thresholdTimestamp, format, accountOptions) => {
    const dateUTC = moment.tz(date, 'UTC');
    if (thresholdTimestamp) {
        const timeSpanInSeconds = moment().unix() - dateUTC.unix();
        if (timeSpanInSeconds > thresholdTimestamp) {
            return getLocalString(date, format, accountOptions);
        }
    }
    return dateUTC.fromNow();
};

const RelativeDateRenderer = (props) => {
    const { onMouseOver, onMouseOut, date } = props;
    const dateUTC = moment.tz(date, 'UTC');
    return (
        <span onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            {dateUTC.fromNow()}
        </span>
    );
};

RelativeDateRenderer.propTypes = {
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired
};

const RelativeTimeRendererWithTooltip = withToolTip(RelativeDateRenderer);

const RelativeTime = (props) => {
    const {
        date, format, thresholdTimestamp, accountOptions
    } = props;
    const dateString = getLocalString(date, format, accountOptions);

    if (thresholdTimestamp) {
        const dateUTC = moment.tz(date, 'UTC');
        const timeSpanInSeconds = moment().unix() - dateUTC.unix();
        if (timeSpanInSeconds > thresholdTimestamp) {
            return <span>{dateString}</span>;
        }
    }

    return <RelativeTimeRendererWithTooltip tooltip={dateString} date={date} />;
};

RelativeTime.propTypes = {
    date: PropTypes.string.isRequired, // expected in "YYYY-MM-DD HH:mm:ss" format in UTC timezone
    format: PropTypes.oneOf(['date', 'datetime']),
    thresholdTimestamp: PropTypes.number,
    accountOptions: customPropTypes.accountOptions.isRequired
};

RelativeTime.defaultProps = {
    format: 'date'
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(RelativeTime);
