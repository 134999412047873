export const ownershipTypes = ['user', 'shared'];

export const ownershipEntities = ['folder', 'dashboard'];

export const getFolderIconByOwnersipType = (ownershipType, defaultIcon) => {
    switch (ownershipType) {
        case 'user':
            return 'lock';
        case 'shared':
            return 'share';
        default:
            return defaultIcon;
    }
};

export const getReadableOwnershipType = (ownershipType) => {
    switch (ownershipType) {
        case 'user':
            return 'private';
        case 'shared':
            return 'shared';
        default:
            return '';
    }
};

export const getReadableOwnershipAndEntityType = (ownershipType, entityType) => `${getReadableOwnershipType(ownershipType)} ${entityType}`;
