import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNaN from 'lodash/isNaN';
import _parseInt from 'lodash/parseInt';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { getNewLinesWithoutEmptyStrings } from 'src/utils/array';

export const hexValue = (value) => (
    value && !/^#([a-f]|[A-F]|[0-9]){3}(([a-f]|[A-F]|[0-9]){3})?$\b/.test(value) ? 'The hex value does not meet the requirements' : undefined
);

export const required = (value) => (value ? undefined : 'This field is required');

export const customRequired = (value, errorMessage) => (value ? undefined : errorMessage);

export const validateJSON = (value, errorMessage) => {
    if (value) {
        try {
            JSON.parse(value);
        } catch (err) {
            return errorMessage;
        }
    }
    return undefined;
};

export const validateOnlyIfHasValue = (value, validate) => (_isEmpty(value) ? undefined : validate(value));

export const makeRangeValidator = (min, max, errorMessage = null) => (value) => (
    value && (value.length < min || value.length > max) ? (errorMessage || `The value must be between ${min} and ${max} characters long`) : undefined
);

export const emailValidator = (email) => {
    if (email) {
        if (email.split('\n').length > 1) {
            return 'The email should be in one line';
        }
        if (email !== '') {
            // eslint-disable-next-line no-useless-escape
            if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
                return 'Please enter a valid email address';
            }
        }
    }
    return undefined;
};

export const validateMultipleEmails = (emailRecipients) => {
    const emails = emailRecipients ? emailRecipients.trim() : null;
    if (emails && emails.length > 0) {
        const errorMessage = 'Email addresses entered have to be valid. Multiple email addresses need to be separated by comma.';
        if (emails.split('\n').length > 1) {
            return errorMessage;
        }
        const emailList = emails.split(',');
        for (let i = 0; i < emailList.length; i++) {
            const currentEmail = emailList[i].trim();
            const valid = emailValidator(currentEmail);
            if (valid !== undefined) {
                return valid;
            }
        }
    }
    return undefined;
};

export const validateDeliveryTime = (time) => {
    const timeCheck = /^([0-1][0-9]|2[0-3]):(00|15|30|45)$/;
    if (time.time && !timeCheck.test(time.time.trim())) {
        return 'Please double check the time entered. The correct format is HH:MM.';
    }
    if (time.timezone === '') {
        return 'Please select a valid timezone';
    }
    return undefined;
};

export const makeLengthValidator = (min, max) => (value) => {
    if (value) {
        if (value.length < min) {
            return `This field has to be at least ${min} characters long`;
        }
        if (value.length > max) {
            return `This field has to be maximum ${max} characters long`;
        }
        return undefined;
    }
    return undefined;
};

export const isStringsEqual = (str1, str2, fieldName) => (
    (!_isEqual(str1, str2)) ? `The ${fieldName} does not match` : undefined
);

export const isNumber = (value) => ((!_isNaN(value) && !_isNaN(_parseInt(value))) ? undefined : 'should be a number');

export const validateMaxLength = (value, maxLength) => {
    if (value && value.length > maxLength) {
        return `Maximum ${maxLength} characters permitted`;
    }
    return undefined;
};

export const makeValidateImage = (maxSize, minWidth, minHeight, allowedMimeTypes) => (value) => {
    const error = _get(value, 'error', false);
    if (error) {
        return error;
    }
    const meta = _get(value, 'meta', false);
    if (meta) {
        const {
            width, height, size, type
        } = meta;
        const allowedTypesLength = allowedMimeTypes.length;
        if (type && allowedTypesLength > 0 && _includes(allowedMimeTypes, type) === false) {
            return `Only ${allowedMimeTypes.join(', ')} ${allowedTypesLength > 1 ? 'formats' : 'format'} allowed.`;
        }
        if (size && (size > maxSize)) {
            return `Maximum ${maxSize / 1000000} MB file size permitted`;
        }
        if ((width && height) && (width < minWidth || height < minHeight)) {
            return `Image must be at least ${minWidth}px x ${minHeight}px.`;
        }
    }
    return undefined;
};

export const validateNumberOfLinks = (maxSize) => (value) => {
    if (value) {
        const lines = getNewLinesWithoutEmptyStrings(value);
        return lines.length > maxSize ? `Should not exceed more than ${maxSize} URLs` : undefined;
    }
    return undefined;
};

export const validateDateSelection = (dateSelection) => {
    if (dateSelection) {
        // check if date is present
        if ((!dateSelection.from || !dateSelection.to) && !dateSelection.dynamicDate) {
            return 'Please select a date.';
        }

        if (!dateSelection.interval) {
            return 'Please select an interval.';
        }

        if (!dateSelection.timezone) {
            return 'Please select a timezone.';
        }
    } else {
        return 'Please select a date.';
    }

    return undefined;
};

export const validateProfileSelection = (profileSelection) => (profileSelection === false ? 'Please select a profile/group.' : undefined);

const containsWhitespace = (str) => /\s/.test(str);

export const validateAColumnName = (columnName) => (containsWhitespace(columnName) ? 'Column should not contain white spaces.' : undefined);
