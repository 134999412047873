import { getIntervalDetailByIntervalTypeOrNull } from 'src/utils/dashboardFolders';
import { parseReportDateSelection } from 'src/sagas/utils';
import _parseInt from 'lodash/parseInt';

export const standardScheduleType = 'standardReportSchedule';
export const advancedScheduleType = 'advancedReportSchedule';

const isCronScheduleEnabled = (scheduleType) => scheduleType === advancedScheduleType;
const getScheduleType = (isCronEnabled) => (isCronEnabled ? advancedScheduleType : standardScheduleType);

const startOfIntervalDetailMonthly = '1';
const startOfIntervalDetailWeekly = '0';

export const createInitialFormValuesFromDashboardReport = (report) => {
    const initialValues = Object.assign({}, report);
    Object.assign(initialValues, {
        additionalFilterSelection: report.additionalFilterSelection !== null ? report.additionalFilterSelection : {}
    });

    Object.assign(initialValues, {
        standardReportSchedule: {
            intervalType: report.scheduleIntervalType ? report.scheduleIntervalType : 'monthly',
            reportTime: report.scheduleTime !== null ? report.scheduleTime : '9:00',
            timezone: report.scheduleTimezone,
            intervalDetailWeekly: report.scheduleIntervalType === 'weekly' ? report.scheduleIntervalDetail : startOfIntervalDetailWeekly,
            intervalDetailMonthly: (report.scheduleIntervalType === 'monthly' || report.scheduleIntervalType === 'quarterly') ? report.scheduleIntervalDetail : startOfIntervalDetailMonthly
        },
        advancedReportSchedule: {
            cronExpression: report.cronScheduleExpression ? report.cronScheduleExpression : '0 9 2 * *',
            timezone: report.scheduleTimezone
        }
    });

    initialValues.reportScheduleType = getScheduleType(report.isCronScheduleEnabled);
    return initialValues;
};

export const createInitialFormValuesForNewReport = (dashboard, accountOptions, profileSelection, additionalFilter) => ({
    isActive: true,
    dashboardId: dashboard.id,
    profileSelection,
    dateSelection: { dynamicDate: 'last 7 days', interval: 'daily', timezone: accountOptions.timezone },
    additionalFilterSelection: additionalFilter,
    notes: '',
    recipients: '',
    emailMessage: '',
    showMetricDesc: false,
    attachShareLink: false,
    standardReportSchedule: {
        intervalType: 'monthly',
        reportTime: '09:00',
        timezone: accountOptions.timezone,
        intervalDetailWeekly: startOfIntervalDetailWeekly,
        intervalDetailMonthly: startOfIntervalDetailMonthly,
    },
    advancedReportSchedule: {
        cronExpression: '0 9 2 * *',
        timezone: accountOptions.timezone
    },
    reportScheduleType: standardScheduleType,
    scheduleCronTranslation: 'Every day at 9:00am',
    format: 'pdf'
});

export const getDashboardReportParams = (formValues, dashboardType) => {
    const {
        intervalType, intervalDetailWeekly, intervalDetailMonthly, reportTime
    } = formValues.standardReportSchedule;
    return {
        id: formValues.id ? _parseInt(formValues.id) : null,
        dashboardId: _parseInt(formValues.dashboardId),
        dashboardType,
        isActive: formValues.isActive,
        recipients: formValues.recipients,
        emailMessage: formValues.emailMessage,
        notes: formValues.notes,
        showMetricDesc: formValues.showMetricDesc,
        attachShareLink: formValues.attachShareLink,
        format: formValues.format,
        profileSelection: JSON.stringify(formValues.profileSelection),
        dateSelection: JSON.stringify(parseReportDateSelection(formValues.dateSelection)),
        additionalFilterSelection: JSON.stringify(formValues.additionalFilterSelection),
        scheduleTimezone: formValues.sheduleUseCronExpression ? formValues.advancedReportSchedule.timezone : formValues.standardReportSchedule.timezone,
        scheduleIntervalType: intervalType,
        scheduleIntervalDetail: getIntervalDetailByIntervalTypeOrNull(intervalType, intervalDetailWeekly, intervalDetailMonthly),
        scheduleTime: reportTime,
        isCronScheduleEnabled: isCronScheduleEnabled(formValues.reportScheduleType),
        cronScheduleExpression: formValues.advancedReportSchedule.cronExpression,
    };
};
