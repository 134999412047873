import { createSelector } from 'reselect';

export const getAdAccountUseCaseAuthUsers = (state) => state.entities.adAccountUseCasesAuthUsers;

export const makeSelectAdAccountUseCasesAuthUsersByAdAccountId = () => createSelector(
    [
        getAdAccountUseCaseAuthUsers,
        (_, adAccountId) => adAccountId
    ],
    (adAccountUseCasesAuthUsers, adAccountId) => adAccountUseCasesAuthUsers.filter((adAccountUseCasesAuthUser) => adAccountUseCasesAuthUser.adAccountId === adAccountId)
);

export const makeSelectAdAccountUseCasesAuthUsersByAuthUserId = () => createSelector(
    [
        getAdAccountUseCaseAuthUsers,
        (_, authUserId) => authUserId
    ],
    (adAccountUseCasesAuthUsers, authUserId) => adAccountUseCasesAuthUsers.filter((adAccountUseCasesAuthUser) => adAccountUseCasesAuthUser.userId === authUserId)
);
