import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { makeStringSorter, makeNumberSorter, createDeepEqualSelector } from 'src/selectors/utils';
import { getShowInactiveDataPushTasks } from 'src/selectors/showInactiveDataPushTasks';
import { makeSelectDataPushLogByIds } from 'src/selectors/dataPushTaskLogs';

export const getDataPushTasksFromStore = (state) => state.entities.dataPushTasks.byIds;
export const getDataPushTasksIdsFromStore = (state) => state.entities.dataPushTasks.allIds;
export const getDataPushTaskAsyncState = (state) => state.entities.dataPushTasks.asyncStates;
export const getDataPushTaskLogsAsyncStates = (state) => state.entities.dataPushTasks.asyncStates.dataPushTaskLogs;
export const getDataPushTaskLatestLogIds = (state) => state.entities.dataPushTasks.dataPushTaskLatestLogIds;
export const getListTableConfig = (state) => state.entities.dataPushTasks.listTableConfig;

export const makeSelectDataPushTaskById = () => createSelector(
    [
        getDataPushTasksFromStore,
        (_, id) => id
    ],
    (dataPushTasks, id) => dataPushTasks[id] || false
);

export const makeSelectDataPushTasks = () => createSelector(
    [
        getDataPushTasksIdsFromStore,
        getDataPushTasksFromStore
    ],
    (dataPushTaskIds, dataPushTasks) => dataPushTaskIds.map((dataPushTaskId) => dataPushTasks[dataPushTaskId]).sort(makeStringSorter('destinationType', 'asc'))
);

export const makeSelectHasAtLeastOneInActiveDataPushTask = () => {
    const selectDataPushTasks = makeSelectDataPushTasks();
    return createSelector(
        [
            selectDataPushTasks
        ],
        (dataPushTasks) => {
            let hasInActive = false;
            dataPushTasks.some((dataPushTask) => {
                if (!dataPushTask.isActive) {
                    hasInActive = true;
                    return true;
                }
                return false;
            });
            return hasInActive;
        }
    );
};

export const makeSelectDataPushLogAsyncStateByDataPushTaskId = () => createSelector(
    [
        getDataPushTaskLogsAsyncStates,
        (_, dataPushTaskId) => dataPushTaskId
    ],
    (dataPushTaskLogsAsyncStates, dataPushTaskId) => _get(dataPushTaskLogsAsyncStates, [dataPushTaskId, 'isPending'], false)
);

export const makeSelectDataPushTaskLatestLogIdByDataPushTaskId = () => createSelector(
    [
        getDataPushTaskLatestLogIds,
        (_, dataPushTaskId) => dataPushTaskId
    ],
    (allDataPushTaskLatestLogIds, dataPushTaskId) => allDataPushTaskLatestLogIds[dataPushTaskId] || null
);

export const makeSelectDataPushTasksLoadingState = () => createSelector(
    [
        getDataPushTaskAsyncState
    ],
    (asyncStates) => _get(asyncStates, ['getAll', 'dataPushTasks'], {})
);

export const makeSelectDataPushTaskLoadingState = () => createSelector(
    [
        getDataPushTaskAsyncState,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['get', id], {})
);

export const makeSelectIsDataPushTaskDryRunning = () => createSelector(
    [
        getDataPushTaskAsyncState,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['dryRun', id, 'isPending'], false)
);

export const makeSelectSortedDataPushTasks = () => {
    const selectDataPushLogByIds = makeSelectDataPushLogByIds();
    return createDeepEqualSelector(
        [
            getDataPushTasksIdsFromStore,
            getDataPushTasksFromStore,
            (state) => {
                const dataPushTaskIds = getDataPushTaskLatestLogIds(state);
                const dataPushTaskLogs = selectDataPushLogByIds(state, Object.values(dataPushTaskIds));
                return dataPushTaskLogs;
            },
            getListTableConfig
        ],
        (dataPushTaskIds, dataPushTasks, dataPushTaskLogs, { sortBy, sortDir }) => {
            if (sortBy === 'startTime' || sortBy === 'rowInserted') {
                const sorter = sortBy === 'startTime' ? makeStringSorter : makeNumberSorter;
                const sortedLogs = dataPushTaskLogs.sort(sorter(sortBy, sortDir));
                const sortedDataPushTaskIds = sortedLogs.map((log) => log.dataPushTaskId);
                const allDataPushTaskIds = _uniq([...sortedDataPushTaskIds, ...dataPushTaskIds]);
                return allDataPushTaskIds.map((dataPushTaskId) => dataPushTasks[dataPushTaskId]);
            }
            return dataPushTaskIds.map((dataPushTaskId) => dataPushTasks[dataPushTaskId]).sort(makeStringSorter(sortBy, sortDir));
        }
    );
};

export const makeSelectDataPushTasksBasedOnShowInactiveDataPushTasksFlag = () => {
    const selectSortedDataPushTasks = makeSelectSortedDataPushTasks();
    return createDeepEqualSelector(
        [
            selectSortedDataPushTasks,
            getShowInactiveDataPushTasks
        ],
        (dataPushTasks, showInactiveDataPushTasks) => {
            if (showInactiveDataPushTasks === false) {
                return dataPushTasks.filter((dataPushTask) => dataPushTask.isActive === true);
            }
            return dataPushTasks;
        }
    );
};

export const makeSelectExistingDataSourceNamesFromDataPushTasks = () => {
    const selectDataPushTasks = makeSelectDataPushTasks();
    return createSelector(
        [
            selectDataPushTasks
        ],
        (dataPushTasks) => {
            const existingDataSourceNames = [];
            dataPushTasks.forEach(({ dataSource }) => {
                if (dataSource) {
                    existingDataSourceNames.push(dataSource);
                }
            });
            return _uniq(existingDataSourceNames);
        }
    );
};
