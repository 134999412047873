import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { makeSearchQueryFilter } from 'src/selectors/accounts';
import {
    defaultLoadingState,
    makePropertyExtractor,
    makeStringSortDirSorter,
    makeNumberSortDirSorter,
    makeStringSorter,
    createShallowEqualSelector
} from 'src/selectors/utils';
import _intersection from 'lodash/intersection';
import _pick from 'lodash/pick';
import { getPostTagsPostsInfo } from 'src/selectors/postTagsPostInfo';

export const getPostTagsFromStore = (state) => state.entities.postTags.byId;
export const getActivePostTagIds = (state) => state.entities.postTags.activePostTagIds;
export const getArchivedPostTagIds = (state) => state.entities.postTags.archivedPostTagIds;
export const getAsyncStates = (state) => state.entities.postTags.asyncStates;
export const getListTableConfig = (state) => state.entities.postTags.listTableConfig;

export const selectActivePostTags = createSelector(
    [
        getActivePostTagIds,
        getPostTagsFromStore
    ], (activePostTagIds, postTagsFromStore) => _pick(postTagsFromStore, activePostTagIds)
);

export const makeSelectActivePostTags = () => createSelector(
    [
        getActivePostTagIds,
        getPostTagsFromStore
    ],
    (activePostTagIds, postTags) => activePostTagIds.map((postTagId) => postTags[postTagId])
);

export const makeSelectActivePostTagsByIds = () => createSelector(
    [
        getActivePostTagIds,
        getPostTagsFromStore,
        (_, ids) => ids
    ],
    (activePostTagIds, postTags, ids) => _intersection(activePostTagIds, ids).map((postTagId) => postTags[postTagId])
);

export const makeSelectActiveAndArchivedPostTags = () => createSelector(
    [
        getActivePostTagIds,
        getArchivedPostTagIds,
        getPostTagsFromStore
    ],
    (activePostTagIds, archivedPostTagIds, postTags) => [...activePostTagIds, ...archivedPostTagIds].map((postTagId) => postTags[postTagId])
);

export const makeSelectAllPostTagIds = () => createSelector(
    [
        getActivePostTagIds,
        getArchivedPostTagIds,
    ], (activePostTagIds, archivedPostTagIds) => [...activePostTagIds, ...archivedPostTagIds]
);

export const selectHasAtleastOneArchivedPostTag = createSelector(
    [
        getArchivedPostTagIds
    ],
    (archivedPostIds) => archivedPostIds.length > 0
);

export const makeSelectPostTagById = () => createSelector(
    [
        getPostTagsFromStore,
        (_, id) => id
    ],
    (postTags, id) => postTags[id] || false
);

export const makeSelectActivePostTagById = () => createSelector(
    [
        selectActivePostTags,
        (_, id) => id
    ],
    (postTags, id) => postTags[id]
);

export const makeSelectPostTagsByIds = () => createSelector(
    [
        getPostTagsFromStore,
        (_, ids) => ids,
    ],
    (postTags, ids) => {
        const availablePostTags = [];
        ids.forEach((id) => {
            if (postTags[id]) {
                availablePostTags.push(postTags[id]);
            }
        });
        return availablePostTags.sort(makeStringSorter('name', 'asc'));
    }
);

export const makeSelectSortedActivePostTagIds = () => {
    const idsExtractor = makePropertyExtractor();
    const selectPostTags = makeSelectActivePostTags();
    const searchQueryFilter = makeSearchQueryFilter((items, filterQuery) => items.filter((item) => (
        item.name.toLowerCase().indexOf(filterQuery) > -1
    )));

    return createSelector(
        [
            selectPostTags,
            (_, filterQuery) => filterQuery
        ],
        (activePostTags, filterQuery) => {
            const sorted = searchQueryFilter(activePostTags, filterQuery).sort(makeStringSorter('name', 'asc'));
            return idsExtractor(sorted);
        }
    );
};

export const selectPostTagsDetailGetState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['get', 'postTagsDetail'], defaultLoadingState)
);

export const selectIsPostTagsArchiving = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['archiving', 'state', 'isPending'], false)
);

export const selectIsPostTagsUnarchiving = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['unarchiving', 'state', 'isPending'], false)
);

export const selectNumberPostTagsToBeTaggedGetState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['numberOfHistoricPostsGet', 'state', 'isPending'], false)
);

const getPostsInfoFilter = memoizeOne(
    (numberOfPostsByPostTag, postTagIds) => postTagIds.map((postTagId) => numberOfPostsByPostTag[postTagId])
);

const getPostTagsSelector = memoizeOne((showArchived) => (showArchived ? makeSelectActiveAndArchivedPostTags() : makeSelectActivePostTags()));

export const makeSelectPostTagsWithListFilterApplied = () => {
    const stringSortDirSorter = makeStringSortDirSorter();
    const idsExtractor = makePropertyExtractor();
    const postsInfoPostTagIdExtractor = makePropertyExtractor('postTagId');
    const numberSortDirSorter = makeNumberSortDirSorter();
    return createShallowEqualSelector(
        [
            (state, showArchived) => getPostTagsSelector(showArchived)(state),
            getPostTagsPostsInfo,
            (_, __, sortBy) => sortBy,
            (_, __, ___, sortDir) => sortDir
        ],
        (postTags, postTagsPostsInfo, sortBy, sortDir) => {
            if (sortBy === 'numberOfPosts') {
                const filteredPostsInfo = getPostsInfoFilter(postTagsPostsInfo, idsExtractor(postTags));
                const sortedPostsInfo = numberSortDirSorter(filteredPostsInfo, sortBy, sortDir);
                return postsInfoPostTagIdExtractor(sortedPostsInfo);
            }
            const sorted = stringSortDirSorter(postTags, sortBy, sortDir);
            return idsExtractor(sorted);
        }
    );
};

export const selectPostTagAutomationActivatingState = createSelector(
    [
        getAsyncStates,
        (_, postTagId) => postTagId
    ],
    (asyncStates, postTagId) => _get(asyncStates, ['automationState', postTagId], defaultLoadingState)
);
