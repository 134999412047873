import { createFormAction } from 'redux-form-saga';

export const REPORT_OVER_VIEWS_DELETE_REQUEST = 'REPORT_OVER_VIEWS_DELETE_REQUEST';
export const REPORT_OVER_VIEWS_DELETE_SUCCESS = 'REPORT_OVER_VIEWS_DELETE_SUCCESS';
export const REPORT_OVER_VIEWS_DELETE_ERROR = 'REPORT_OVER_VIEWS_DELETE_ERROR';

export const reportOverviewDeleteRequest = ({ reportOverviewIds }) => ({
    type: REPORT_OVER_VIEWS_DELETE_REQUEST,
    payload: { reportOverviewIds }
});

export const reportOverviewDeleteSuccess = (successDashboardReportIds, dashboardShareLinkIds) => ({
    type: REPORT_OVER_VIEWS_DELETE_SUCCESS,
    payload: { successDashboardReportIds, dashboardShareLinkIds }
});

export const reportOverviewDeleteError = (error) => ({
    type: REPORT_OVER_VIEWS_DELETE_ERROR,
    payload: { error }
});

export const reportOverviewDeleteRequestFormAction = createFormAction(reportOverviewDeleteRequest, [REPORT_OVER_VIEWS_DELETE_SUCCESS, REPORT_OVER_VIEWS_DELETE_ERROR]);
