import loadingIndicator1 from 'src/resources/loading-indicators/loading-column-chart.gif';
import loadingIndicator2 from 'src/resources/loading-indicators/loading-line-chart.gif';
import loadingIndicator3 from 'src/resources/loading-indicators/loading-pie-chart.gif';
import React, { memo } from 'react';
import styles from 'src/stylesheets/loadingScreen.scss';
import PropTypes from 'prop-types';

const loadingIndicators = [
    loadingIndicator1,
    loadingIndicator2,
    loadingIndicator3
];

export const getRandomImage = (random) => {
    if (random) {
        return loadingIndicators[Math.floor(Math.random() * loadingIndicators.length)];
    }
    return loadingIndicators[0];
};

const LoadingScreen = memo(({ random }) => (
    <div className={styles.loadingScreen}>
        <img src={getRandomImage(random)} alt="loading" />
    </div>
));

LoadingScreen.propTypes = {
    random: PropTypes.bool
};

LoadingScreen.defaultProps = {
    random: true
};

export default LoadingScreen;
