import {
    makeStringSortDirSorter,
    makeNumberSortDirSorter,
    createShallowEqualSelector,
    makeBooleanSortDirSorter
} from 'src/selectors/utils';
import { makeSelectAllDashboardReports, makeSelectDashboardReportsByIds } from 'src/selectors/dashboardReports';
import { makeSelectDashboardShareLinks, makeSelectDashboardShareLinkByIds } from 'src/selectors/dashboardShareLinks';
import { createSelector } from 'reselect';
import {
    getDashboardAndShareLinkIds,
    getReportOverviewFromReportsAndShareLinks,
    getReportOverviewIdForDashboardReport,
    getReportOverviewIdForDashboardShareLink
} from 'src/utils/reportOverviews';

export const makeSelectReportOverview = () => {
    const selectAllDashboardReports = makeSelectAllDashboardReports();
    const selectDashboardShareLinks = makeSelectDashboardShareLinks();

    return createSelector(
        [
            selectAllDashboardReports,
            selectDashboardShareLinks
        ],
        (dashboardReports, dashboardShareLinks) => getReportOverviewFromReportsAndShareLinks(dashboardReports, dashboardShareLinks)
    );
};

export const makeSelectReportOverviewsByIds = () => {
    const selectDashboardReportsByIds = makeSelectDashboardReportsByIds();
    const selectDashboardShareLinkByIds = makeSelectDashboardShareLinkByIds();
    return createShallowEqualSelector(
        [
            (state, reportOverviewIds) => {
                const { dashboardReportIds, dashboardShareLinkIds } = getDashboardAndShareLinkIds(reportOverviewIds);
                return {
                    dashboardReports: selectDashboardReportsByIds(state, dashboardReportIds),
                    dashboardShareLinks: selectDashboardShareLinkByIds(state, dashboardShareLinkIds)
                };
            }
        ],
        ({ dashboardReports, dashboardShareLinks }) => getReportOverviewFromReportsAndShareLinks(dashboardReports, dashboardShareLinks)
    );
};

export const makeSelectReportOverviewIds = () => {
    const selectAllDashboardReports = makeSelectAllDashboardReports();
    const selectDashboardShareLinks = makeSelectDashboardShareLinks();
    return createSelector(
        [
            selectAllDashboardReports,
            selectDashboardShareLinks
        ],
        (dashboardReports, dashboardShareLinks) => {
            const dashboardReportIds = dashboardReports.map((dashboardReport) => getReportOverviewIdForDashboardReport(dashboardReport));
            return dashboardReportIds.concat(dashboardShareLinks.map((dashboardShareLink) => getReportOverviewIdForDashboardShareLink(dashboardShareLink)));
        }
    );
};

export const makeSelectReportOverviewWithFiltersApplied = () => {
    const selectReportOverview = makeSelectReportOverview();
    const stringSortDirSorter = makeStringSortDirSorter();
    const numberSortDirSorter = makeNumberSortDirSorter();
    const booleanSortDirSorter = makeBooleanSortDirSorter();
    return createSelector(
        [
            selectReportOverview,
            (_, sortBy) => sortBy,
            (_, __, sortDir) => sortDir
        ],
        (reportOverviews, sortBy, sortDir) => {
            if (sortBy === 'numberOfVisits') {
                return numberSortDirSorter(reportOverviews, sortBy, sortDir);
            }
            if (sortBy === 'isActive') {
                return booleanSortDirSorter(reportOverviews, sortBy, sortDir);
            }
            return stringSortDirSorter(reportOverviews, sortBy, sortDir);
        }
    );
};
