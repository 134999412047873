import { createSelector } from 'reselect';

export const getVisualizationsFromStore = (state) => state.entities.visualizations.byId;
const getViszalizationIdsFromStore = (state) => state.entities.visualizations.allIds;

export const selectVisualizations = createSelector(
    [
        getViszalizationIdsFromStore,
        getVisualizationsFromStore
    ],
    (ids, visualizations) => ids.map((id) => visualizations[id])
);

export const selectVisualizationsByPos = createSelector(
    [
        selectVisualizations
    ],
    (visualizations) => visualizations.sort((a, b) => a.pos - b.pos)
);

export const makeSelectVisualizationById = () => createSelector(
    [
        getVisualizationsFromStore,
        (_, id) => id
    ],
    (visualizations, id) => visualizations[id] || false
);

export const makeSelectCompatibleVisualizationsById = () => {
    const selectVisualizationById = makeSelectVisualizationById();
    return createSelector(
        [
            selectVisualizationById,
            selectVisualizationsByPos
        ],
        (selectedVisualization, allVisualizations) => allVisualizations.filter((visualization) => visualization.supportedDataTypeId === selectedVisualization.supportedDataTypeId && visualization.type !== 'api')
    );
};
