import { createSlice, createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

const initialState = {};

const name = 'dashboardMetricNoteEdit';

const dashboardMetricNoteEdit = createSlice({
    name,
    initialState,
    reducers: {
        setEditMode: {
            reducer(state, action) {
                const { dashboardMetricId, isEdit } = action.payload;
                state[dashboardMetricId] = isEdit;
            },
            prepare(dashboardMetricId, isEdit) {
                return { payload: { dashboardMetricId, isEdit } };
            }
        },
    }
});

export const selectIsInEditMode = createSelector(
    (state) => state[name],
    (_, dashboardMetricId) => dashboardMetricId,
    (values, dashboardMetricId) => _get(values, dashboardMetricId, false)
);

export const { setEditMode } = dashboardMetricNoteEdit.actions;
export default dashboardMetricNoteEdit.reducer;
