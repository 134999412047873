import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { formName as metricBuilderFormName } from 'src/utils/metricBuilder';
import { postTagRuleAddFormName } from 'src/utils/postTagRule';
import { postTagEditFormName } from 'src/utils/postTag';

export const selectMetricBuilderFormButtonClicked = createSelector(
    [
        (state) => _get(state, ['form', metricBuilderFormName], false)
    ],
    (form) => {
        if (form) {
            return _get(form, 'buttonClicked', false);
        }
        return false;
    }
);

export const selectPostTagRuleIgnoreCheck = createSelector(
    [
        (state) => _get(state, ['form', postTagRuleAddFormName], false)
    ],
    (form) => {
        if (form) {
            return _get(form, 'ignoreCheck', false);
        }
        return false;
    }
);

export const selectPostTagEditFormNameIgnoreCheck = createSelector(
    [
        (state) => _get(state, ['form', postTagEditFormName], false)
    ],
    (form) => {
        if (form) {
            return _get(form, 'ignoreCheck', false);
        }
        return false;
    }
);
