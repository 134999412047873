import { isDashboardShareLinkExpired } from 'src/utils/dashboardShareLink';

export const dashboardReportType = 'scheduledReport';
export const dashboardShareLinkType = 'interactiveReport';
export const listName = 'reportsOverview';

export const getReportOverviewIdForDashboardReport = (dashboardReport) => `${dashboardReportType}-${dashboardReport.id}`;
export const getReportOverviewIdForDashboardShareLink = (dashboardShareLink) => `${dashboardShareLinkType}-${dashboardShareLink.id}`;

export const getTypeAndId = (dashboardReportOrShareLinkId) => {
    const parts = dashboardReportOrShareLinkId.split('-');
    return {
        type: parts[0],
        id: parts[1],
    };
};

export const getDashboardAndShareLinkIds = (dashboardReportOrShareLinks) => {
    const dashboardReportIds = [];
    const dashboardShareLinkIds = [];
    dashboardReportOrShareLinks.forEach((dashboardReportOrShareLink) => {
        const { type, id } = getTypeAndId(dashboardReportOrShareLink);
        if (type === dashboardReportType) {
            dashboardReportIds.push(id);
        }

        if (type === dashboardShareLinkType) {
            dashboardShareLinkIds.push(id);
        }
    });
    return {
        dashboardReportIds,
        dashboardShareLinkIds
    };
};

export const getReportOverviewFromDashboardReport = (dashboardReport) => ({
    type: dashboardReportType,
    id: getReportOverviewIdForDashboardReport(dashboardReport),
    name: dashboardReport.dashboardName,
    creator: dashboardReport.createdByUserName,
    createTime: dashboardReport.createTime,
    numberOfVisits: null,
    lastVisitTime: null,
    isActive: dashboardReport.isActive
});

export const getReportOverviewFromDashboardShareLinks = (dashboardShareLink) => ({
    type: dashboardShareLinkType,
    id: getReportOverviewIdForDashboardShareLink(dashboardShareLink),
    name: dashboardShareLink.dashboardName,
    creator: dashboardShareLink.createdByUserName,
    createTime: dashboardShareLink.createTime,
    numberOfVisits: dashboardShareLink.numberOfVisits,
    lastVisitTime: dashboardShareLink.lastVisitTime,
    isActive: !isDashboardShareLinkExpired(dashboardShareLink)
});

export const getReportOverviewFromReportsAndShareLinks = (dashboardReports, dashboardShareLinks) => {
    const reports = [];
    dashboardReports.forEach((dashboardReport) => {
        reports.push(getReportOverviewFromDashboardReport(dashboardReport));
    });

    dashboardShareLinks.forEach((dashboardShareLink) => {
        reports.push(getReportOverviewFromDashboardShareLinks(dashboardShareLink));
    });

    return reports;
};

export const getDeleteReportOverviewSentence = (reportOverviews) => (reportOverviews.length > 1 ? 'You are about to delete these reports. This will not delete the dashboards of the reports.'
    : 'You are about to delete this report. This will not delete the dashboard of the report.');
