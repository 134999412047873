import {
    makeOrderBySorter, makePlatformTypeFilter, makePropertyExtractor, makeSearchQueryFilter
} from 'src/selectors/utils';
import { createSelector } from 'reselect';
import { formName } from 'src/utils/dataSources';
import { getFormValues } from 'redux-form';
import _get from 'lodash/get';

export const getDataSourceListStatus = (state) => state.dataSourceListStatus;
export const getDataSourcesFromStore = (state) => state.entities.dataSources.byIds;
export const getDataSourceIdsFromStore = (state) => state.entities.dataSources.allIds;
export const getDashboardAsyncStatesByAction = (state) => state.entities.dataSources.asyncStatesByAction;
export const getColumnsByDataSourceId = (state) => state.entities.dataSources.columnsByDataSourceId;

export const makeSelectDataSourceState = () => createSelector(
    [
        getDataSourceListStatus,
        (_, tableName) => tableName
    ],
    (dataSourceListStatus, tableName) => dataSourceListStatus[tableName] || false
);

export const selectDataSourceLoadingState = createSelector(
    [
        getDashboardAsyncStatesByAction
    ],
    (asyncStates) => _get(asyncStates, ['getData', 'dataSources'], {})
);

export const makeSelectDataSources = () => createSelector(
    [
        getDataSourceIdsFromStore,
        getDataSourcesFromStore
    ],
    (dataSourceIds, dataSources) => dataSourceIds.map((id) => dataSources[id])
);

export const selectDataSourceById = createSelector(
    [
        (_, id) => id,
        getDataSourcesFromStore
    ],
    (id, dataSources) => dataSources[id] || false
);

const makeTypeFilter = (property, type = '') => createSelector(
    [
        (items) => items,
        (_, statsType) => statsType
    ],
    (items, statsType) => {
        if (statsType === 'all') {
            return items;
        }
        const isPrivateStatistic = statsType === type;
        return items.filter((item) => item[property] === isPrivateStatistic);
    }
);

export const makeSelectDataSourceIdsWithListFiltersApplied = () => {
    const platformTypeFilter = makePlatformTypeFilter('allowedPlatformType');
    const statsTypeFilter = makeTypeFilter('isPrivateStatistic', 'private');
    const dataTypeFilter = makeTypeFilter('isTimeSeries', 'timeSeries');
    const searchQueryFilter = makeSearchQueryFilter();
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('name');

    return (state, allDataSources, platformType, statsType, dataType, filterBy, orderBy) => {
        const platformFiltered = platformTypeFilter(allDataSources, platformType);
        const statsFiltered = statsTypeFilter(platformFiltered, statsType);
        const dataTypeFiltered = dataTypeFilter(statsFiltered, dataType);
        const queryFiltered = searchQueryFilter(dataTypeFiltered, filterBy);
        const sorted = sorter(queryFiltered, orderBy);
        return idsExtractor(sorted);
    };
};

export const makeSelectLinkToDataSources = () => {
    const selectFormValues = getFormValues(formName);
    return createSelector(
        [
            selectFormValues
        ],
        (formValues) => {
            const pathname = '/data-sources';
            if (formValues) {
                const {
                    platformType, statsType, dataType, term, sortBy
                } = formValues;
                return {
                    pathname,
                    query: {
                        platformType, statsType, dataType, term, sortBy
                    }
                };
            }
            return {
                pathname
            };
        }
    );
};

export const makeSelectDataSourceColumnsByDataSourceId = () => createSelector(
    [
        getColumnsByDataSourceId,
        (_, dataSourceId) => dataSourceId
    ],
    (columnsByDataSourceId, dataSourceId) => columnsByDataSourceId[dataSourceId] || []
);
