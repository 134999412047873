import _get from 'lodash/get';
import { defaultPostTextExcludeState, defaultPostTextState, defaultProfileState } from 'src/utils/filterSelectors';
import moment from 'moment-timezone';
import _parseInt from 'lodash/parseInt';

export const postTagRuleAddFormName = 'postTagRuleAddForm';

export const getInitialFormValues = (postTagRule, postTag, duplicateRule, defaultTimezone) => {
    if (postTagRule) {
        const postText = _get(postTagRule.additionalFilterSelection, 'postText', defaultPostTextState);
        const postTextExclude = _get(postTagRule.additionalFilterSelection, 'postTextExclude', defaultPostTextExcludeState);
        const { values: postTextValues, conjunction: postTextConjunction } = postText;
        const { values: postTextExcludeValues, conjunction: postTextExcludeConjunction } = postTextExclude;
        const initialValues = {
            name: postTagRule.name,
            profileSelection: postTagRule.profileSelection,
            postTextValues,
            postTextConjunction,
            postTextExcludeValues,
            postTextExcludeConjunction,
            from: _get(postTagRule, 'dateSelection.from'),
            to: _get(postTagRule, 'dateSelection.to'),
            timezone: _get(postTagRule, 'dateSelection.timezone'),
            postTagId: postTag.id,
            automationEnabled: postTag.automation
        };

        if (duplicateRule === false) {
            initialValues.postTagRuleId = postTagRule.id;
        }

        return initialValues;
    }
    return {
        name: '',
        profileSelection: defaultProfileState,
        postTextValues: defaultPostTextState.values,
        postTextConjunction: defaultPostTextState.conjunction,
        postTextExcludeValues: defaultPostTextExcludeState.values,
        postTextExcludeConjunction: defaultPostTextExcludeState.conjunction,
        from: moment().subtract(180, 'days').format('YYYY-MM-DD'),
        to: null,
        timezone: defaultTimezone,
        postTagId: postTag.id,
        automationEnabled: postTag.automation
    };
};

export const getDateSelectionArray = (from, to, timezone) => ({
    from,
    to,
    timezone
});

export const getRuleEndDate = (from) => {
    const now = moment().format('YYYY-MM-DD');
    return (now < from) ? from : now;
};

export const getDateSelectionForSearchArray = ({
    from,
    to,
    timezone
}) => ({
    from,
    to: to || getRuleEndDate(from),
    interval: 'total',
    timezone
});

const defaultFormat = 'YYYY.MM.DD';
export const hasPostTagRuleJobCompleted = (endTime) => {
    if (endTime !== null) {
        const formattedEndTime = moment(endTime).format(defaultFormat);
        const now = moment().format(defaultFormat);
        return now > formattedEndTime;
    }
    return false;
};

export const hasAllPostTagRulesJobCompleted = (endTimes) => {
    const states = endTimes.map((endTime) => hasPostTagRuleJobCompleted(endTime));
    return states.every((state) => state === true);
};

const createAdditionalFilterSelection = (postText, postTextExclude) => ({ postText, postTextExclude });

export const getPostTagRuleRequestParams = (name, dateSelection, profileSelection, postText, postTextExclude, postTagId, postTagRuleId = null) => {
    const params = {
        name,
        dateSelection: JSON.stringify(dateSelection),
        profileSelection: JSON.stringify(profileSelection),
        additionalFilterSelection: JSON.stringify(createAdditionalFilterSelection(postText, postTextExclude)),
        postTagId: _parseInt(postTagId, 10)
    };
    if (postTagRuleId) {
        params.postTagRuleId = _parseInt(postTagRuleId);
    }
    return params;
};

const isAdditionalFilterDuplicate = (additionalFilterSelectionA, additionalFilterSelectionB) => {
    // make sure both (postText and postTextExclude) exists, else comparison will be not possible.
    const postTextA = _get(additionalFilterSelectionA, 'postText', defaultPostTextState);
    const postTextExcludeA = _get(additionalFilterSelectionA, 'postTextExclude', defaultPostTextExcludeState);
    const completeAdditionalFilterA = createAdditionalFilterSelection(postTextA, postTextExcludeA);

    const postTextB = _get(additionalFilterSelectionB, 'postText', defaultPostTextState);
    const postTextExcludeB = _get(additionalFilterSelectionB, 'postTextExclude', defaultPostTextExcludeState);
    const completeAdditionalFilterB = createAdditionalFilterSelection(postTextB, postTextExcludeB);

    return JSON.stringify(completeAdditionalFilterA) === JSON.stringify(completeAdditionalFilterB);
};

const isDuplicate = (ruleA, ruleB) => (
    JSON.stringify(ruleA.dateSelection) === JSON.stringify(ruleB.dateSelection)
    && JSON.stringify(ruleA.profileSelection) === JSON.stringify(ruleB.profileSelection)
    && isAdditionalFilterDuplicate(ruleA.additionalFilterSelection, ruleB.additionalFilterSelection)
);

export const isPostTagRuleDuplicate = (from, to, timezone, profileSelection, postText, postTextExclude, postTagRules) => {
    const postTagRule = {
        dateSelection: getDateSelectionArray(from, to, timezone),
        profileSelection,
        additionalFilterSelection: { postText, postTextExclude }
    };
    return postTagRules.some((currentPostTagRule) => isDuplicate(postTagRule, currentPostTagRule));
};
