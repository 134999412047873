import { createSelector } from 'reselect';
import _includes from 'lodash/includes';
import { makePropertyExtractor } from 'src/selectors/utils';

export const getDashboardReportsFromStore = (state) => state.entities.dashboardReports.byId;
export const getDashboardReportIdsFromStore = (state) => state.entities.dashboardReports.allIds;
export const getDashboardReportAsyncStates = (state) => state.entities.dashboards.asyncStates;

export const makeSelectDashboardReportById = () => createSelector(
    [
        getDashboardReportsFromStore,
        (_, dashboardReportId) => dashboardReportId
    ],
    (dashboardReports, dashboardReportId) => dashboardReports[dashboardReportId] || false
);

export const makeSelectAllDashboardReports = () => createSelector(
    [
        getDashboardReportIdsFromStore,
        getDashboardReportsFromStore
    ],
    (allIds, dashboardReports) => allIds.map((id) => dashboardReports[id])
);

export const makeSelectDashboardReportsByIds = () => {
    const selectAllDashboardReports = makeSelectAllDashboardReports();
    return createSelector(
        [
            selectAllDashboardReports,
            (_, dashboardReportIds) => dashboardReportIds
        ],
        (allReports, dashboardReportIds) => allReports.filter(({ id }) => _includes(dashboardReportIds, id))
    );
};

export const makeSelectDashboardReportsByDashboardId = () => {
    const selectAllDashboardReports = makeSelectAllDashboardReports();
    return createSelector(
        [
            selectAllDashboardReports,
            (_, dashboardId) => dashboardId
        ],
        (allReports, dashboardId) => allReports.filter((report) => report.dashboardId === dashboardId)
    );
};

export const makeSelectDashboardReportIdsByDashboardIds = () => {
    const selectAllDashboardReports = makeSelectAllDashboardReports();
    const propertyExtractor = makePropertyExtractor('id');
    return createSelector(
        [
            selectAllDashboardReports,
            (_, dashboardIds) => dashboardIds
        ],
        (dashboardReports, dashboardIds) => {
            const filteredDashboardReports = dashboardReports.filter(({ dashboardId }) => _includes(dashboardIds, dashboardId));
            return propertyExtractor(filteredDashboardReports);
        }
    );
};
