import _get from 'lodash/get';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _intersection from 'lodash/intersection';

import { createSelector } from 'reselect';
import {
    makeSelectFolderById, makeSelectFolderIdsByParentId, getFoldersFromStore, makeSelectAllChildrenIdsByParentId
} from 'src/selectors/folders';
import { makeSelectDashboardIdsByFolderId } from 'src/selectors/dashboards';

const getEntityTypeAllEntityIdsFromStore = (state, entity, type) => {
    const path = _get(state, ['ownership', entity, type, 'allEntityIds']);
    if (path === undefined) {
        throw new Error(`${entity} ${type}  not found`);
    }
    return path;
};

const getTypeLookUpFromStore = (state, entity) => {
    const path = _get(state, ['ownership', entity, 'lookup']);
    if (path === undefined) {
        throw new Error(`${entity} not found`);
    }

    return path;
};

const makeSelectFolderByTypeAndId = () => {
    const selectFolderById = makeSelectFolderById();
    return (state, type, id) => {
        // filter
        const allEntityIds = getEntityTypeAllEntityIdsFromStore(state, 'folder', type);
        if (_find(allEntityIds, (value) => value === id)) {
            return selectFolderById(state, id, allEntityIds);
        }
        return false;
    };
};

export const makeSelectFolderByFolderIdAndType = () => {
    const selectFolderByTypeAndId = makeSelectFolderByTypeAndId();
    return (state, type, id) => {
        if (!type) {
            return false;
        }
        // if id === 'root' we use type as parentRoot?
        const parsedId = id === 'root' ? type : id;
        return selectFolderByTypeAndId(state, type, parsedId);
    };
};

export const makeSelectFolderIdsByParentIdAndType = () => {
    const selectFolderIdsByParentId = makeSelectFolderIdsByParentId();
    return createSelector([
        (state, type, id, sortBy = 'name', sortDir = 'asc') => {
            const parsedId = id === type ? 'root' : id;
            return selectFolderIdsByParentId(state, parsedId, sortBy, sortDir);
        },
        (state, type) => getEntityTypeAllEntityIdsFromStore(state, 'folder', type)
    ],
    (folderIds, allEntityIds) => folderIds.filter((folderId) => _find(allEntityIds, (value) => value === folderId)));
};

export const selectEntityTypeByEntityAndId = (state, entity, entityId) => {
    const lookup = getTypeLookUpFromStore(state, entity);
    return _get(lookup, entityId);
};

export const selectFolderTypeById = (state, folderId) => selectEntityTypeByEntityAndId(state, 'folder', folderId);

export const selectDashboardTypeById = (state, dashboardId) => selectEntityTypeByEntityAndId(state, 'dashboard', dashboardId);

export const selectFolderTypesByIds = (state, folderIds) => {
    const folderTypesById = {};
    folderIds.forEach((folderId) => {
        Object.assign(folderTypesById, { [folderId]: selectFolderTypeById(state, folderId) });
    });
    return folderTypesById;
};

export const selectDashboardTypesByIds = (state, dashboardIds) => {
    const dashboardTypesById = {};
    dashboardIds.forEach((dashboardId) => {
        Object.assign(dashboardTypesById, { [dashboardId]: selectDashboardTypeById(state, dashboardId) });
    });
    return dashboardTypesById;
};

export const makeSelectParentFolderIdsOfFolderId = () => createSelector(
    [
        getFoldersFromStore,
        (_, folderId) => folderId,
        (x, y, type) => type
    ],
    (allFolders, folderId, type) => {
        const parentIds = [];

        if (_has(allFolders, folderId)) {
            let currentFolderId = folderId;

            parentIds.unshift(currentFolderId);
            while (_has(allFolders[currentFolderId], 'parentId')) {
                const parentId = allFolders[currentFolderId].parentId === 'root' ? type : allFolders[currentFolderId].parentId;
                parentIds.unshift(parentId);
                currentFolderId = parentId;
            }
        }
        return parentIds;
    }
);

export const makeSelectDashboardIdsByFolderIdAndType = () => {
    const selectDashboardIdsByFolderId = makeSelectDashboardIdsByFolderId();
    return createSelector([
        (state, folderId, type, sortBy = 'name', sortDir = 'asc') => selectDashboardIdsByFolderId(state, folderId === type ? 'root' : folderId, sortBy, sortDir),
        (state, x, type) => getEntityTypeAllEntityIdsFromStore(state, 'dashboard', type),
    ],
    (dashboardIds, typeIds) => _intersection(dashboardIds, typeIds));
};

export const makeSelectDashboardCountByFolderIdsAndType = () => {
    const selectDashboardIdsByFolderIdAndType = makeSelectDashboardIdsByFolderIdAndType();
    return (state, folderIds, type) => {
        let dashboardCount = 0;
        folderIds.forEach((folderId) => {
            dashboardCount += selectDashboardIdsByFolderIdAndType(state, folderId, type).length;
        });
        return dashboardCount;
    };
};

export const makeSelectAllChildrenIdsByParentIdAndType = () => {
    const selectAllChildrenIdsByParentId = makeSelectAllChildrenIdsByParentId();
    return createSelector([
        (state, parentId, type) => selectAllChildrenIdsByParentId(state, parentId === type ? 'root' : parentId),
        (state, x, type) => getEntityTypeAllEntityIdsFromStore(state, 'folder', type)
    ],
    (folderIds, typeIds) => _intersection(folderIds, typeIds));
};
