import * as customPropTypes from 'src/customPropTypes';
import Label from 'src/components/labels/Label';
import PropTypes from 'prop-types';
import React from 'react';
import _lowerFirst from 'lodash/lowerFirst';
import { makeSelectDashboardReportById } from 'src/selectors/dashboardReports';
import { connect } from 'react-redux';

export const getTextForActiveReport = (dashboardReport) => {
    if (dashboardReport.isCronScheduleEnabled) {
        const { scheduleCronTranslation } = dashboardReport;
        return scheduleCronTranslation !== null ? _lowerFirst(scheduleCronTranslation) : '';
    }
    if (dashboardReport.scheduleIntervalType === 'weekly') {
        const dayOfWeek = {
            0: 'Monday',
            1: 'Tuesday',
            2: 'Wednesday',
            3: 'Thursday',
            4: 'Friday',
            5: 'Saturday',
            6: 'Sunday'
        };

        return `weekly on ${dayOfWeek[dashboardReport.scheduleIntervalDetail]}s at ${dashboardReport.scheduleTime}`;
    } if (dashboardReport.scheduleIntervalType === 'monthly') {
        return `monthly at ${dashboardReport.scheduleTime}`;
    } if (dashboardReport.scheduleIntervalType === 'daily') {
        return `daily at ${dashboardReport.scheduleTime}`;
    }
    return `${dashboardReport.scheduleIntervalType}`;
};

const ReportStatusLabel = (props) => {
    const { dashboardReport } = props;
    if (dashboardReport === false) {
        return null;
    }
    const { isActive } = dashboardReport;
    if (!isActive) {
        return <Label state="disabled" text="Unscheduled" />;
    }

    return (
        <Label state="on" text={`Scheduled ${getTextForActiveReport(dashboardReport)}`} />
    );
};

ReportStatusLabel.propTypes = {
    dashboardReportId: PropTypes.string.isRequired,
    dashboardReport: PropTypes.oneOfType([customPropTypes.dashboardReport, PropTypes.bool]).isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardReportById = makeSelectDashboardReportById();
    return (state, ownProps) => {
        const { dashboardReportId } = ownProps;
        return {
            dashboardReport: selectDashboardReportById(state, dashboardReportId)
        };
    };
};

export default connect(makeMapStateToProps)(ReportStatusLabel);
