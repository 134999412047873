import { createSelector } from 'reselect';

export const getUserNotificationById = (state) => state.userNotification.byId;
export const getMenuUserNotificationIdsFromStore = (state) => state.userNotification.menuNotifications.notificationMenuIds;
export const getMenuUserNotificationsRequestStateFromStore = (state) => state.userNotification.menuNotifications.menuRequestState;
export const getUserNotificationIndicator = (state) => state.userNotification.menuNotifications.indicator;
export const getPageUserNotificationIdsFromStore = (state) => state.userNotification.pageNotifications.notificationPageIds;
export const getPageUserNotificationsRequestStateFromStore = (state) => state.userNotification.pageNotifications.pageRequestState;
export const getUserNotificationSettings = (state) => state.userNotification.userNotificationSettings;

export const selectMenuNotifications = createSelector(
    [
        getMenuUserNotificationIdsFromStore,
        getUserNotificationById
    ],
    (userNotificationIds, userNotifications) => userNotificationIds.map((userNotificationId) => userNotifications[userNotificationId])
);

export const selectNotificationById = createSelector(
    [
        getUserNotificationById,
        (_, notificationId) => notificationId
    ],
    (notifications, notificationId) => notifications[notificationId] || false
);

export const selectUserNotificationSettingsFormInitialValues = createSelector(
    [
        getUserNotificationSettings
    ],
    (userNotificationSettings) => ({
        sendUserNotificationsViaEmail: userNotificationSettings.sendUserNotificationsViaEmail
    })
);
