import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { makeStringSorter } from 'src/selectors/utils';

export const getDataPushTaskLogsFromStore = (state) => state.entities.dataPushTaskLogs.byIds;
export const getDataPushTaskLogsIdsFromStore = (state) => state.entities.dataPushTaskLogs.allIds;
export const getDataPushTaskAsyncState = (state) => state.entities.dataPushTaskLogs.asyncStates;
export const getDataPushTaskLogData = (state) => state.entities.dataPushTaskLogs.logData;

export const selectDataPushLogs = createSelector(
    [
        getDataPushTaskLogsIdsFromStore,
        getDataPushTaskLogsFromStore
    ],
    (ids, dataPushTaskLogs) => ids.map((id) => dataPushTaskLogs[id])
);

export const makeSelectDataPushLogById = () => createSelector(
    [
        getDataPushTaskLogsFromStore,
        (_, id) => id
    ],
    (dataPushTaskLogs, id) => dataPushTaskLogs[id] || false
);

export const makeSelectDataPushLogByIds = () => createSelector(
    [
        getDataPushTaskLogsFromStore,
        (_, ids) => ids
    ],
    (dataPushTaskLogs, ids) => ids.map((id) => dataPushTaskLogs[id])
);

export const makeSelectDataPushTaskLogsByDataPushTaskId = () => createSelector(
    [
        selectDataPushLogs,
        (_, dataPushTaskId) => dataPushTaskId
    ],
    (dataPushTaskLogs, dataPushTaskId) => {
        const filteredDataPusheLogs = dataPushTaskLogs.filter((dataPushLog) => dataPushLog.dataPushTaskId === dataPushTaskId);
        return filteredDataPusheLogs.slice().sort(makeStringSorter('scheduleTime', 'desc'));
    }
);

export const makeSelectDataPushLogLoadingState = () => createSelector(
    [
        getDataPushTaskAsyncState,
        (_, dataPushTaskLogId) => dataPushTaskLogId
    ],
    (asyncStates, dataPushTaskLogId) => _get(asyncStates, ['get', dataPushTaskLogId], {})
);

export const makeSelectDataPushTaskLogData = () => createSelector(
    [
        getDataPushTaskLogData,
        (_, dataPushTaskLogId) => dataPushTaskLogId
    ],
    (logs, dataPushTaskLogId) => logs[dataPushTaskLogId] || false
);

export const makeSelectIsDataPushLogDataLoading = () => createSelector(
    [
        getDataPushTaskAsyncState,
        (_, dataPushTaskLogId) => dataPushTaskLogId
    ],
    (asyncStates, dataPushTaskLogId) => _get(asyncStates, ['getData', dataPushTaskLogId], {})
);
