import { createSelector } from 'reselect';

export const getProfileUseCaseAuthUsers = (state) => state.entities.profileUseCasesAuthUsers;

export const makeSelectProfileUseCasesAuthUsersByProfileId = () => createSelector(
    [
        getProfileUseCaseAuthUsers,
        (_, profileId) => profileId
    ],
    (allProfilesUseCasesAuthUsers, profileId) => allProfilesUseCasesAuthUsers.filter((profilesUseCasesAuthUser) => profilesUseCasesAuthUser.profileId === profileId)
);

export const makeSelectProfileUseCasesAuthUsersByAuthUserId = () => createSelector(
    [
        getProfileUseCaseAuthUsers,
        (_, authUserId) => authUserId
    ],
    (allProfilesUseCasesAuthUsers, authUserId) => allProfilesUseCasesAuthUsers.filter((profilesUseCasesAuthUser) => profilesUseCasesAuthUser.userId === authUserId)
);

export const makeSelectProfileUseCasesAuthUsersByAuthUserIdAndProfileId = () => createSelector(
    [
        getProfileUseCaseAuthUsers,
        (_, authUserId) => authUserId,
        (_, __, profileId) => profileId
    ], (profileUseCaseAuthUsers, authUserId, profileId) => profileUseCaseAuthUsers.filter((profileUseCaseAuthUser) => profileUseCaseAuthUser.userId === authUserId && profileUseCaseAuthUser.profileId === profileId)
);
