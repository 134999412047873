import { createSelector } from 'reselect';
import { makeOrderBySorter } from 'src/selectors/accounts';
import { makePropertyExtractor, createShallowEqualSelector, defaultLoadingState } from 'src/selectors/utils';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import { getNumberOfAdAccountsToSelect, listName } from 'src/utils/adAccountSearch';
import _get from 'lodash/get';

export const getSocialNetworkAuthenticatedSearch = (state) => state.adAccountSearch.authenticatedSearch;
export const getAdAccountSearchResultsById = (state) => state.adAccountSearch.searchedAdAccountsById;
export const getIsAuthenticatedSearchResultPage = (state) => state.adAccountSearch.isAuthenticatedSearchResultPage;

// auth profile search
export const getAuthAdAccountSearchState = (state) => state.adAccountSearch.authAdAccountSearch.search;
export const getSearchedAdAccountIds = (state) => state.adAccountSearch.authAdAccountSearch.searchedAdAccountIds;

export const makeSelectAdAccountSearchedById = () => createSelector(
    [
        getAdAccountSearchResultsById,
        (_, id) => id
    ],
    (searchedAccount, id) => searchedAccount[id] || null
);

export const makeSelectSearchedAdAccountByIds = () => {
    const sorter = makeOrderBySorter();
    return createSelector(
        [
            getAdAccountSearchResultsById,
            (_, ids) => ids
        ],
        (searchedProfiles, ids) => {
            const searchedProfilesArray = ids.map((id) => searchedProfiles[id]);
            return sorter(searchedProfilesArray, 'alphabetically');
        }
    );
};

export const makeSelectAdAccountTempIdsSortedAlphabitically = () => {
    const selectSearchedProfilesByIds = makeSelectSearchedAdAccountByIds();
    const idExtractor = makePropertyExtractor();
    return createSelector(
        [
            selectSearchedProfilesByIds
        ], (result) => idExtractor(result)
    );
};

export const makeSelectCurrentAdAccountLimitCountAndType = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return createShallowEqualSelector(
        [
            selectSpaceLimits,
            (state) => selectCheckedValues(state, listName)
        ],
        (spaceLimits, checkedSearchedProfiles) => getNumberOfAdAccountsToSelect(spaceLimits, checkedSearchedProfiles.length)
    );
};

export const makeSelectAuthAdAccountSearchState = () => createSelector(
    [
        getAuthAdAccountSearchState
    ],
    (authProfileSearchState) => _get(authProfileSearchState, ['state'], defaultLoadingState)
);
