import {
    onResponse,
    recv,
} from 'http-client';
import _get from 'lodash/get';
import { modalsShowSessionInvalidModal } from 'src/actions/overlays';

// Copy of http-client parse only with different ErrorText
export const parseWithOwnErrorText = (parser, as = 'body') => recv((response) => {
    const error = new Error(''
        + 'It seems our server has timed out due to the size of data. '
        + 'Please select a smaller time frame or reduce the number of profiles.');
    error.serverError = true;
    if (as in response) {
        return response[as];
    }
    if (!response.ok && parser === 'blob') {
        return response.json().then((body) => {
            Object.assign(response, { jsonData: body });
            return response;
        }, () => {
            throw error;
        });
    }
    return response[parser]().then((body) => {
        Object.assign(response, { [as]: body });
        return response;
    }, () => {
        throw error;
    });
});

// dispatch on session;
let globalDispatch = null;
export function registerDispatchToSessionExceptionHandler(dispatch) {
    globalDispatch = dispatch;
}

export const exceptionOnNotOk = () => onResponse((response) => {
    if (!response.ok) {
        const errorMessage = _get(response, 'jsonData.errors.reason', response.statusText);
        const error = new Error(errorMessage);
        error.response = response;
        error.errorType = _get(response, 'jsonData.errors.errorType', null);
        error.payload = _get(response, 'jsonData.errors.payload', null);
        error.requestInfo = _get(response, 'jsonData.requestInfo', null);
        error.serverError = true;
        if (error.errorType === 'sessionInvalid') {
            if (globalDispatch) {
                globalDispatch(modalsShowSessionInvalidModal(errorMessage));
            }
        }
        throw error;
    }
    return response;
});

export const createAuthenticator = ({ getAccessTokenSilently, logout }, createLogout, type, additionalHeaderFactory = null) => {
    const handleAuthorizedServerRequest = async (serverRequest, to) => {
        const accessToken = await getAccessTokenSilently();
        const headers = { Authorization: `${type} ${accessToken}` };
        if (additionalHeaderFactory) {
            const additionalHeaders = additionalHeaderFactory();
            Object.assign(headers, additionalHeaders);
        }
        return serverRequest(to, { headers });
    };

    const enhancedLogout = createLogout(handleAuthorizedServerRequest, logout);
    return {
        getAccessTokenSilently,
        type,
        handleAuthorizedServerRequest,
        logout: enhancedLogout
    };
};
