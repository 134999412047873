import { getDashboardsFromStore, selectDashboards } from 'src/selectors/dashboards';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import { createSelector } from 'reselect';
import {
    makeConcatenator, makeExcludeFilter, makeOrderBySorter, makeSearchQueryFilter, makeStringSorter
} from './utils';

export const getFoldersFromStore = (state) => state.entities.folders.byId;

const getFolderIdsFromStore = (state) => state.entities.folders.allIds;

const getFolderAsyncStatesFromStore = (state) => state.entities.folders.asyncStatesByAction;
const getModalFolderLocation = (state) => state.entities.folders.modalFolderLocation;

export const getFolderByIdFromStore = (state, id) => {
    const folders = getFoldersFromStore(state);

    return folders[id] || false;
};

export const makeSelectFolderById = () => createSelector(
    [
        getFolderByIdFromStore
    ],
    (folder) => folder
);

export const selectFolders = createSelector(
    [
        getFolderIdsFromStore,
        getFoldersFromStore
    ],
    (folderIds, folders) => folderIds.map((folderId) => folders[folderId])
);

export const makeSelectFoldersByParentId = () => createSelector(
    [
        selectFolders,
        (_, parentId) => parentId,
        (_x, _y, sortBy) => sortBy,
        (_x, _y, _z, sortDir) => sortDir,
    ],
    (folders, parentId, sortBy = 'name', sortDir = 'asc') => (
        folders.filter((folder) => folder.parentId === parentId)
            .sort(makeStringSorter(sortBy, sortDir)))
);

export const makeSelectFolderIdsByParentId = () => {
    const selectFoldersByParentId = makeSelectFoldersByParentId();
    return createSelector(
        [
            selectFoldersByParentId
        ],
        (folders) => folders.map((folder) => folder.id)
    );
};

export const makeSelectFoldersByFolderIds = () => createSelector(
    [
        getFoldersFromStore,
        (_, folderIds) => folderIds,
    ],
    (folders, folderIds) => folderIds.map((folderId) => folders[folderId])
);

const folderHasChild = (folderIdToCheck, dashboards, folders) => {
    const hasFolders = Object.keys(folders).some((folderId) => {
        const folderToCheck = folders[folderId];
        return _has(folderToCheck, 'parentId') && folderToCheck.parentId === folderIdToCheck;
    });
    return hasFolders || Object.keys(dashboards).some((dashboardId) => {
        const dashboardToCheck = dashboards[dashboardId];
        return dashboardToCheck.folderId === folderIdToCheck;
    });
};

export const makeSelectFoldersSplitIntoChildrenOrNot = () => createSelector(
    [
        getFoldersFromStore,
        getDashboardsFromStore,
        (state, folderIds) => folderIds

    ],
    (folders, dashboards, folderIds) => {
        const foldersWithChildren = [];
        const foldersWithoutChildren = [];

        folderIds.forEach((folderId) => {
            const folder = folders[folderId];
            if (folderHasChild(folderId, dashboards, folders)) {
                foldersWithChildren.push(folder);
            } else {
                foldersWithoutChildren.push(folder);
            }
        });

        return {
            foldersWithoutChildren,
            foldersWithChildren
        };
    }
);

export const makeSelectFolderLink = () => createSelector(
    [
        (_, folderId) => folderId
    ],
    (folderId) => Object.assign({}, { pathname: `/folder/${folderId}` })
);

const getFolderChildrenIds = (folders, parentFolderId) => {
    const allChildrenIds = [];
    const children = _filter(folders, (folder) => folder.parentId === parentFolderId);

    children.forEach((child) => {
        allChildrenIds.push(child.id);
        allChildrenIds.push(...getFolderChildrenIds(folders, child.id));
    });
    return allChildrenIds;
};

export const makeSelectAllChildrenIdsByParentId = () => createSelector(
    [
        getFoldersFromStore,
        (state, parentFolderId) => parentFolderId
    ],
    (folders, parentFolderId) => getFolderChildrenIds(folders, parentFolderId)
);

export const selectIsFolderAndDashboardMovePending = createSelector(
    [
        (state) => getFolderAsyncStatesFromStore(state).move,
        (_, id) => id
    ],
    (asyncActions, id) => _get(asyncActions, [id, 'isPending'], false)
);

export const selectIsDashboardSavePending = createSelector(
    [
        (state) => getFolderAsyncStatesFromStore(state).save,
        (_, id) => id
    ],
    (asyncActions, id) => _get(asyncActions, [id, 'isPending'], false)
);

const makeExtractIdAndSetType = (type) => createSelector(
    [
        (items) => items
    ],
    (items) => items.map((item) => ({ id: item.id, type }))
);

export const makeSelectDashboardAndFolderIdForSearch = () => {
    const folderSearchQueryFilter = makeSearchQueryFilter('name', true);
    const dashboardsQueryFilter = makeSearchQueryFilter('name', true);
    const folderSorter = makeOrderBySorter();
    const dashboardSorter = makeOrderBySorter();
    const concatenator = makeConcatenator();
    const folderExtractIdAndSetType = makeExtractIdAndSetType('folder');
    const dashboardExtractIdAndSetType = makeExtractIdAndSetType('dashboard');
    const excludeFilter = makeExcludeFilter('id');

    return (state, searchTerm) => {
        const folders = selectFolders(state);
        const dashboards = selectDashboards(state);
        const filteredFolders = folderSearchQueryFilter(folders, searchTerm);
        const filteredDashboards = dashboardsQueryFilter(dashboards, searchTerm);
        const exlcudeFilteredFolders = excludeFilter(filteredFolders, '0');
        const sortedFolders = folderSorter(exlcudeFilteredFolders);
        const sortedDashboards = dashboardSorter(filteredDashboards);
        const folderIdAndType = folderExtractIdAndSetType(sortedFolders);
        const dashboardIdAndType = dashboardExtractIdAndSetType(sortedDashboards);
        return concatenator(folderIdAndType, dashboardIdAndType);
    };
};

export const selectModalFolderLocation = createSelector(
    [
        getModalFolderLocation,
    ],
    (folderLocation) => folderLocation
);
