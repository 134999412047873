import _isString from 'lodash/isString';

export const splitIntoDashboardsAndFolders = (folderAndDashboardIds) => {
    const folderIds = [];
    const dashboardIds = [];
    folderAndDashboardIds.forEach((folderOrDashboard) => {
        const splitedItem = folderOrDashboard.split('-');
        if (splitedItem[0] === 'folderListItem') {
            folderIds.push(splitedItem[1]);
        } else if (splitedItem[0] === 'dashboardListItem') {
            dashboardIds.push(splitedItem[1]);
        }
    });
    return { folderIds, dashboardIds };
};

export const SUPER_ROOT = 'SUPER_ROOT';

export const makeSort = (direction) => (a, b) => {
    const alc = _isString(a.name) ? a.name.toLowerCase().trim() : '';
    const blc = _isString(b.name) ? b.name.toLowerCase().trim() : '';

    if (alc > blc) {
        return direction === 'desc' ? -1 : 1;
    }

    if (alc < blc) {
        return direction === 'desc' ? 1 : -1;
    }
    return 0;
};

export const getIntervalDetailByIntervalTypeOrNull = (intervalType, intervalDetailWeekly, intervalDetailMonthly) => {
    // Set intervalDetail according to the selected intervalType
    if (intervalType === 'weekly') {
        return intervalDetailWeekly;
    }
    if (intervalType === 'monthly' || intervalType === 'quarterly') {
        return intervalDetailMonthly;
    }
    return null;
};
