import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import numeral from 'numeral';
import { getRelativeTimeString } from 'src/components/RelativeTime';

export const profileNamesByPlatformType = {
    facebook: 'Facebook',
    instagram: 'Instagram',
    twitter: 'X',
    youtube: 'Youtube',
    linkedIn: 'LinkedIn',
    snapchatShow: 'Snapchat Show',
    tiktok: 'TikTok',
    threads: 'Threads',
};

export const getAudienceSizeString = (value, platformType, hiddenAudienceSize) => {
    const formattedValue = numeral(value).format('0,0');

    let unit;
    switch (platformType) {
        case 'facebook':
            unit = 'fans/followers';
            break;

        case 'youtube':
        case 'snapchatShow':
            unit = 'subscribers';
            break;

        case 'twitter':
        case 'instagram':
        case 'linkedIn':
        case 'tiktok':
        default:
            unit = 'followers';
            break;
    }

    if (hiddenAudienceSize) {
        return `Public ${unit} count hidden by profile`;
    }

    if (value < 1) {
        return 'Not yet available';
    }

    return `${formattedValue} ${unit}`;
};

export const getNetworkName = (platformType) => _get(profileNamesByPlatformType, platformType, _upperFirst(platformType));

export const platformTypes = Object.keys(profileNamesByPlatformType);

export const networkFilterOptions = [{ value: 'all', label: 'All social networks' }].concat(Object.keys(profileNamesByPlatformType).map((platformType) => (
    { value: platformType, label: profileNamesByPlatformType[platformType] })));

const metaFilter = { value: 'meta', label: 'Meta' };
export const datasourceFilterOptions = networkFilterOptions.concat([{ value: 'general', label: 'General' }, metaFilter]);

export const authTransactionNetworkFilter = networkFilterOptions.concat([metaFilter]);

export const getAudienceSizeAndAddedDateInfo = (profile, accountOptions) => {
    const {
        addedTime, addedUserFirstname, likes, platformType, hiddenAudienceSize
    } = profile;
    return `${getAudienceSizeString(likes, platformType, hiddenAudienceSize)} · Added ${getRelativeTimeString(addedTime, 30 * 86400, 'date', accountOptions)}${addedUserFirstname !== null ? ` by ${addedUserFirstname}` : ''} `;
};
