import { createSelector } from 'reselect';
import _get from 'lodash/get';
import {
    defaultLoadingState,
    makeStringSorter,
    makePropertyExtractor,
    makeStringSortDirSorter, createShallowEqualSelector
} from 'src/selectors/utils';
import _includes from 'lodash/includes';
import { makeSelectGroupsByIds } from 'src/selectors/groups';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';

export const getPostTagRuleAllIdsFromStore = (store) => store.entities.postTagRules.allIds;
export const getPostTagRulesFromStore = (store) => store.entities.postTagRules.byId;
export const getPostTagRulesListTableConfig = (store) => store.entities.postTagRules.listTableConfig;
export const getAsyncStates = (state) => state.entities.postTagRules.asyncStates;

export const makeSelectPostTagRules = () => createSelector(
    [
        getPostTagRuleAllIdsFromStore,
        getPostTagRulesFromStore
    ],
    (allIds, postTagRules) => allIds.map((id) => postTagRules[id])
);

export const makeSelectPostTagRuleById = () => createSelector(
    [
        getPostTagRulesFromStore,
        (_, postTagRuleId) => postTagRuleId
    ],
    (postTagRules, postTagRuleId) => postTagRules[postTagRuleId] || false
);

export const makeSelectPostTagRuleByIds = () => createSelector(
    [
        getPostTagRulesFromStore,
        (_, postTagRuleIds) => postTagRuleIds
    ],
    (postTagRules, postTagRuleIds) => {
        const availablePostTagRules = [];
        postTagRuleIds.forEach((id) => {
            if (postTagRules[id]) {
                availablePostTagRules.push(postTagRules[id]);
            }
        });
        return availablePostTagRules.sort(makeStringSorter('name', 'asc'));
    }
);

export const selectPostTagRulesGetState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['get', 'postTagRules'], defaultLoadingState)
);

export const selectPostTagRulesActivateState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['activate', 'state', 'isPending'], false)
);

export const selectNumberPostTagsToBeTaggedGetState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['numberOfHistoricPostsGet', 'state', 'isPending'], false)
);

export const selectPostTagRulesDeactivateState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['deactivate', 'state', 'isPending'], false)
);

export const makeSelectPostTagRulesWithFiltersApplied = () => {
    const selectPostTagRules = makeSelectPostTagRules();
    const stringSortDirSorter = makeStringSortDirSorter();
    const idsExtractor = makePropertyExtractor();
    return createShallowEqualSelector(
        [
            selectPostTagRules,
            (_, sortBy) => sortBy,
            (_, __, sortDir) => sortDir
        ],
        (postTagRules, sortBy, sortDir) => {
            const sorted = stringSortDirSorter(postTagRules, sortBy, sortDir);
            return idsExtractor(sorted);
        }
    );
};

export const makeSelectPostTagRuleIdsByPostTagIds = () => {
    const selectPostTagRules = makeSelectPostTagRules();
    const idsExtractor = makePropertyExtractor();
    return createSelector(
        [
            selectPostTagRules,
            (_, postTagIds) => postTagIds
        ],
        (postTagRules, postTagIds) => idsExtractor(postTagRules.filter((postTagRule) => _includes(postTagIds, postTagRule.postTagId)))
    );
};

export const makeSelectPostTagRulesByPostTagId = () => {
    const selectPostTagRules = makeSelectPostTagRules();
    return createSelector(
        [
            selectPostTagRules,
            (_, postTagId) => postTagId
        ],
        (postTagRules, postTagId) => postTagRules.filter((postTagRule) => postTagRule.postTagId === postTagId)
    );
};

export const makeSelectPostTagRulesProfileInSentence = () => {
    const selectGroupsByIds = makeSelectGroupsByIds();
    const selectProfilesByIds = makeSelectProfilesByIds();
    return createSelector([
        (state, profileSelection) => selectGroupsByIds(state, profileSelection.groupIds),
        (state, profileSelection) => selectProfilesByIds(state, profileSelection.profileIds)
    ],
    (selectedGroups, selectedProfiles) => {
        const mappedGroups = selectedGroups.map((group) => `${group.name} group`);
        const mappedProfiles = selectedProfiles.map((profile) => `${profile.name} profile`);
        const combined = mappedGroups.concat(mappedProfiles);
        if (combined.length === 0) {
            return 'unknown profile';
        }
        if (combined.length === 1) {
            return combined[0];
        }
        const sliced = combined.slice(0, 7);
        const rest = combined.slice(7, combined.length);
        return `(${sliced.join(' OR ')}${rest.length > 0 ? ` OR ${rest.length} other selection${rest.length !== 1 ? 's' : ''}` : ''})`;
    });
};
