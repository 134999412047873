import moment from 'moment';
import googleBigQueryLogo from 'src/resources/illustrations/google_bigquery.png';
import awsS3Logo from 'src/resources/illustrations/aws_s3.png';
import _includes from 'lodash/includes';
import { makeStringSorter } from 'src/selectors/utils';

export const googleBigQueryType = 'googleBigQuery';
export const awsS3Type = 'awsS3';
export const dataPushTaskAddFormName = 'dataPushTaskAddForm';
export const dataPushTaskDestinationAddFormName = 'dataPushTaskDestinationAddForm';
export const dataPushTaskQQLType = 'qql';
export const dataPushTaskDataSourceType = 'dataSource';

export const getTaskLogDuration = (startTime, endTime) => {
    const end = moment(endTime);
    const start = moment(startTime);
    const duration = moment.duration(end.diff(start));
    const seconds = duration.asSeconds();
    return seconds;
};

export const getDataPushTaskPlatformLogoAndLabel = (platformType) => {
    switch (platformType) {
        case 'googleBigQuery': {
            return {
                img: googleBigQueryLogo,
                label: 'Google BigQuery'
            };
        }
        case 'awsS3': {
            return {
                img: awsS3Logo,
                label: 'AWS S3'
            };
        }
        default:
            return { img: '', label: '' };
    }
};

export const getDestinationTypeText = (destinationType) => {
    let destinationTypeText = 'n/a';
    if (destinationType === googleBigQueryType) {
        destinationTypeText = 'Google BigQuery';
    } else if (destinationType === awsS3Type) {
        destinationTypeText = 'AWS S3';
    }

    return destinationTypeText;
};

export const getDataPushTaskInitialValues = (dataPushTask) => {
    const {
        dataSource, dataSourceColumns, dynamicDate, interval, timezone, dataPushTimeColumnName
    } = dataPushTask;
    return {
        dataPushTaskId: dataPushTask.id,
        dataSource,
        dataSourceColumns,
        dynamicDate,
        dataPushTimeColumnName,
        interval,
        timezone,
        deliveryCronExpression: {
            cronExpression: dataPushTask.deliveryCronString,
            timezone: 'UTC'
        }
    };
};

export const getDataPushTaskDestinationInitialValues = (destination) => {
    const { id, dataPushTaskId, destinationType } = destination;

    const initialValues = {
        destinationId: id,
        dataPushTaskId,
        destinationType
    };
    if (destinationType === awsS3Type) {
        const {
            awsS3AccessKeyId,
            awsS3SecretAccessKey,
            awsS3Region,
            awsS3Bucket,
            awsS3FilePrefix,
            awsS3FileFormat
        } = destination;

        Object.assign(initialValues, {
            awsS3AccessKeyId,
            awsS3SecretAccessKey,
            awsS3Region,
            awsS3Bucket,
            awsS3FilePrefix,
            awsS3FileFormat
        });
    }

    if (destinationType === googleBigQueryType) {
        const {
            googleBigQueryProjectId,
            googleBigQueryDatasetId,
            googleBigQueryTableId,
            googleBigQueryTimePartitioningType,
            googleBigQueryTimePartitioningField
        } = destination;

        Object.assign(initialValues, {
            googleBigQueryProjectId,
            googleBigQueryDatasetId,
            googleBigQueryTableId,
            googleBigQueryTimePartitioningType,
            googleBigQueryTimePartitioningField
        });
    }
    return initialValues;
};

export const dryRunInfo = 'A dry run is used to test the initial set-up of a data push task. It does not write any data to your database tables but is a virtual representation of what would have been pushed to your side. Additionally, the logs of the dry run can be downloaded in our interface to check for correctness before automating more frequent pushes.';

export const getDataSourceOptionsForDataPushTasks = (dataSources, allowedNetworks, existingDataSourceNames) => {
    const filteredDataSources = [];
    dataSources.forEach((dataSource) => {
        const { name, allowedPlatformType } = dataSource;
        if (_includes(allowedNetworks, allowedPlatformType) || allowedPlatformType === 'general') {
            filteredDataSources.push(Object.assign({}, dataSource, { isAllowed: true }));
        } else if (_includes(existingDataSourceNames, name)) {
            filteredDataSources.push(Object.assign({}, dataSource, { isAllowed: false }));
        }
    });
    return filteredDataSources.sort(makeStringSorter('name', 'asc'));
};
