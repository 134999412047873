import { createFormAction } from 'redux-form-saga';

export const GET_USER_NOTIFICATIONS_REQUEST = 'GET_USER_NOTIFICATIONS_REQUEST';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_ERROR = 'GET_USER_NOTIFICATIONS_ERROR';

export const CHECK_LATEST_USER_NOTIFICATION = 'CHECK_LATEST_USER_NOTIFICATION_BACKGROUND';
export const CHECK_LATEST_USER_NOTIFICATION_SUCCESS = 'CHECK_LATEST_USER_NOTIFICATION_SUCCESS_BACKGROUND';
export const CHECK_LATEST_USER_NOTIFICATION_ERROR = 'CHECK_LATEST_USER_NOTIFICATION_ERROR_BACKGROUND';

export const UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME = 'UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_BACKGROUND';
export const UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_SUCCESS = 'UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_SUCCESS_BACKGROUND';
export const UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_ERROR = 'UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_ERROR_BACKGROUND';

export const MARK_USER_NOTIFICATION = 'MARK_USER_NOTIFICATION_REQUEST';
export const MARK_USER_NOTIFICATION_SUCCESS = 'MARK_USER_NOTIFICATION_SUCCESS';
export const MARK_USER_NOTIFICATION_ERROR = 'MARK_USER_NOTIFICATION_ERROR';

export const MARK_ALL_USER_NOTIFICATION_AS_READ = 'MARK_ALL_USER_NOTIFICATION_AS_READ_REQUEST';
export const MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS = 'MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_ALL_USER_NOTIFICATION_AS_READ_ERROR = 'MARK_ALL_USER_NOTIFICATION_AS_READ_ERROR';

export const UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST = 'UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_USER_NOTIFICATION_SETTINGS_ERROR = 'UPDATE_USER_NOTIFICATION_SETTINGS_ERROR';

export const getUserNotificationsRequest = (numberOfNotifications = 0, lastNotificationCreateTime = null, reset = false) => ({
    type: GET_USER_NOTIFICATIONS_REQUEST,
    payload: { numberOfNotifications, lastNotificationCreateTime, reset }
});

export const getUserNotificationsSuccess = (userNotifications, lastNotificationCreateTime, next) => ({
    type: GET_USER_NOTIFICATIONS_SUCCESS,
    payload: { userNotifications, lastNotificationCreateTime, next }
});

export const getUserNotificationsError = (error) => ({
    type: GET_USER_NOTIFICATIONS_ERROR,
    payload: {
        error
    }
});

export const checkLatestUserNotificationRequest = () => ({
    type: CHECK_LATEST_USER_NOTIFICATION
});

export const checkLatestUserNotificationSuccess = (userNotifications, hasNewNotifications) => ({
    type: CHECK_LATEST_USER_NOTIFICATION_SUCCESS,
    payload: { userNotifications, hasNewNotifications }
});

export const checkLatestUserNotificationError = (error) => ({
    type: CHECK_LATEST_USER_NOTIFICATION_ERROR,
    payload: {
        error
    }
});

export const updateUserNotificationsLastSeenTime = () => ({
    type: UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME
});

export const updateUserNotificationLastSeenTimeSuccess = (hasNewNotifications) => ({
    type: UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_SUCCESS,
    payload: { hasNewNotifications }
});

export const updateUserNotificationLastSeenTimeError = () => ({
    type: UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_ERROR
});

export const markUserNotification = (userNotificationId, mark = true) => ({
    type: MARK_USER_NOTIFICATION,
    payload: {
        userNotificationId,
        mark
    }
});

export const markUserNotificationSuccess = (userNotificationId, mark) => ({
    type: MARK_USER_NOTIFICATION_SUCCESS,
    payload: {
        userNotificationId,
        mark
    }
});

export const markUserNotificationError = (error) => ({
    type: MARK_USER_NOTIFICATION_ERROR,
    payload: { error }
});

export const markAllUserNotificationAsRead = () => ({
    type: MARK_ALL_USER_NOTIFICATION_AS_READ
});

export const markAllUserNotificationAsReadSuccess = (hasNewNotifications) => ({
    type: MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS,
    payload: { hasNewNotifications }
});

export const markAllUserNotificationAsReadError = (error) => ({
    type: MARK_ALL_USER_NOTIFICATION_AS_READ_ERROR,
    payload: { error }
});

const updateUserNotificationSettingsRequest = (formValues) => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST,
    payload: {
        formValues
    }
});

export const updateUserNotificationSettingsSuccess = (userNotificationSettings) => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { userNotificationSettings }
});

export const updateUserNotificationSettingsError = (error) => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_ERROR,
    payload: error
});

export const updateUserNotificationSettingsRequestFormAction = createFormAction(
    updateUserNotificationSettingsRequest,
    [UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS, UPDATE_USER_NOTIFICATION_SETTINGS_ERROR]
);
