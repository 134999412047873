import { createSelector } from 'reselect';

export const getLastVisited = (state) => state.lastVisited;
export const getDiscoverLastRouteState = (state) => state.lastVisited.discoverRouteState;

export const getLastVisitedDashboardIds = (state) => {
    const lastVisited = getLastVisited(state);
    return lastVisited.dashboards;
};

export const selectLastVisitedDiscoverState = createSelector(
    [
        getDiscoverLastRouteState
    ],
    (discoverRouteState) => discoverRouteState.lastRoute || 'dashboards'
);

export const selectLastVisitedDiscoverMetricState = createSelector(
    [
        getDiscoverLastRouteState
    ],
    (discoverRouteState) => discoverRouteState.metricLastRoute || 'metric'
);
