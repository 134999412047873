import { createSelector } from 'reselect';

export const getCustomMetricFromStore = (state) => state.customMetric;
export const getQQLFormValuesFromStore = (state) => state.customMetric.qqlFormValues;
export const getQQLSelectorValuesFromStore = (state) => state.customMetric.qqlSelectorValues;

export const makeSelectCustomMetricServerDataLoadingState = () => createSelector(
    [
        getCustomMetricFromStore
    ],
    (customMetric) => customMetric.dataLoadingState
);

export const makeSelectCustomMetricRunQQLState = () => createSelector(
    [
        getCustomMetricFromStore
    ],
    (customMetric) => customMetric.runQQLState
);
