export const LOAD_USER_INVITATION_REQUEST = 'LOAD_USER_INVITATION_REQUEST';
export const LOAD_USER_INVITATION_SUCCESS = 'LOAD_USER_INVITATION_SUCCESS';
export const LOAD_USER_INVITATION_ERROR = 'LOAD_USER_INVITATION_ERROR';

export const loadUserInvitationRequest = (invitationCode, userId) => ({
    type: LOAD_USER_INVITATION_REQUEST,
    payload: { invitationCode, userId }
});

export const loadUserInvitationSuccess = (invitationCode, invitation) => ({
    type: LOAD_USER_INVITATION_SUCCESS,
    payload: { data: invitation, invitationCode }
});

export const loadUserInvitationError = (invitationCode, error) => ({
    type: LOAD_USER_INVITATION_ERROR,
    payload: { invitationCode, error }
});
